.header {
    position: sticky;
    left: 0;
    right: 0;
    top: 0;
    padding: to-rem(25px) to-rem($site-gutter);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 50;
    transition: background-color 150ms ease;
    color: $c-secondary;

    @include breakpoint(phablet) {
        padding: to-rem(34px) to-rem($site-gutter--mobile);
    }

    .homepage & {
        opacity: 0;
        transition: opacity 300ms ease-in, background-color 150ms ease;
    }

    .homepage.homepage--end-animation &,
    .homepage.homepage--animation-played & {
        opacity: 1;
    }

    body[data-header-position="sticky"] &,
    &:hover:not(.header--menu-active):not(.header--booking-active) {
        background-color: var(--background);
        color: var(--foreground);
    }

    ~ .inner-wrapper .home-hero__sentinel,
    ~ .inner-wrapper .home-hero,
    ~ .inner-wrapper .hero,
    ~ .inner-wrapper .automated-bands:first-child {
        margin-top: to-rem($u-header-height * -1);

        @include breakpoint(phablet) {
            margin-top: to-rem($u-header-height-mobile * -1);
        }
    }
}

.header--static:not(.header--menu-active) {
    background-color: var(--background);
    color: var(--foreground);
}
.header--static ~ .inner-wrapper .automated-bands:first-child {
    margin-top: 0;

    & > .block:first-child {
        margin-top: 0;
    }
}

.header--menu-active {
    color: var(--foreground);

    @include breakpoint(phablet) {
        background-color: var(--background);
    }
}

    .header__toggle {
        @include font(16px, 20px);

        letter-spacing: 2px;
        color: inherit;
        border-color: inherit;
        padding: 0;
        border: none;

        &:hover {
            color: var(--foreground);
            background-color: transparent;
        }
    }

    .header__booking {
        color: inherit;
        border-color: inherit;

        .header--menu-active & {
            opacity: 0.5;
            pointer-events: none;
        }

        [data-header-position="sticky"] & {
            opacity: 1;
            pointer-events: auto;
        }

        &:hover {
            border-color: var(--foreground);
        }
    }

    .header__logo {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: opacity 300ms ease;
        display: flex;
        align-items: center;
        justify-content: center;

        .header--menu-active & {
            opacity: 0;
        }

        [data-header-position="sticky"] & {
            opacity: 1;
        }

        [data-header-position="sticky"] .header--menu-active & {
            @include breakpoint(phablet) {
                opacity: 0;
            }
        }

        svg {
            transition: fill 150ms ease;
            fill: currentColor;
        }
    }

    .header__awards {
        display: none;
    }
    .homepage--end-animation .header__awards {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 120px;
        gap: 20px;
        top: 105px;
        right: 38px;
        z-index: 10;
        @include breakpoint(tablet) {
            top: 100px;
            width: 80px;
            right: 58px;
            gap: 12px
        }
        @include breakpoint(phablet) {
            position: sticky;
            top: -70px;
            bottom: to-rem(70px);
            left: to-rem($site-gutter--mobile);
            right: to-rem($site-gutter--mobile);
            width: calc(100% - (2 * $site-gutter--mobile));
            gap: 10px;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            z-index: 51;
        }
    }

    body[data-header-position="sticky"],
    .header:hover:not(.header--menu-active):not(.header--booking-active)~main {
        .header__awards {
            z-index: 10;
        }
    }

    // for header__awards on mobile
    .home-hero:has(~ .header__awards) {
        @include breakpoint(phablet) {
            margin-bottom: -90px;
        }
    }

    main:has(.header__awards) {
        ~ .footer {
            @include breakpoint(phablet) {
                padding-top: to-rem(70px);

                .footer__decoration {
                    top: to-rem(65px);
                }
            }
        }
    }
