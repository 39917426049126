@use 'sass:math';

.block--gallery {
    padding-left: calc((1 / 12) * 100%);
    padding-right: calc((1 / 12) * 100%);

    @include breakpoint(phablet) {
        padding: 0;
    }

    .block__gallery {
        overflow: visible;
        position: relative;
        text-align: center;
    }

    .block__title {
        margin-bottom: 0;
    }

    .block__copy {
        margin-bottom: to-rem(35px);

        @include breakpoint(phablet) {
            display: flex;
            flex-direction: column;
            margin: 0 to-rem($site-gutter--mobile) to-rem(15px);
        }
    }

    .block__image {
        @include aspect(math.div(7, 4));

        position: relative;

        @include breakpoint(phablet) {
            @include aspect(math.div(4, 3));
        }
    }

    .block__swiper-container {
        position: relative;
    }

        .block__swiper {
            margin-bottom: to-rem(40px);

            @include breakpoint(phablet) {
                margin-bottom: to-rem(30px);
            }
        }

        .block__nav {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            border: 0;
            height: 45px;
            width: 45px;
            padding: 0;
            display: grid;
            place-items: center;

            @include breakpoint(phablet) {
                display: none;
            }

            &:hover {
                background-color: transparent;
            }

            svg {
                fill: var(--foreground);
            }

            &[aria-disabled="true"] {
                svg {
                    opacity: .5;
                }
            }
        }

        .block__nav--prev {
            left: 0;
            margin-left: to-rem(-50px);
        }

        .block__nav--next {
            right: 0;
            margin-right: to-rem(-50px);
        }
}
