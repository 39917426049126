@font-face{
    font-family:"Circular";
    src: url("/fonts/CircularXXSub-Book.woff2") format("woff2"),
         url("/fonts/CircularXXSub-Book.woff") format("woff"),;
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face{
    font-family:"Circular";
    src: url("/fonts/CircularXXSub-Medium.woff2") format("woff2"),
         url("/fonts/CircularXXSub-Medium.woff") format("woff"),;
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face{
    font-family:"Circular";
    src: url("/fonts/CircularXXSub-Bold.woff2") format("woff2"),
         url("/fonts/CircularXXSub-Bold.woff") format("woff"),;
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
