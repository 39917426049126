.offer-details {}

    .offer-details__description {
        margin-bottom: $u-med-spacer;
    }

    .offer-details__title {
        @include small-title;
        margin-bottom: $u-med-spacer;
    }

    .offer-details__list {
        margin-bottom: $u-med-spacer;
    }

    .offer-details__list-item {
        @include list-item(nowrap);
    }

    .offer-details__link {
        text-decoration: underline;
    }

    .offer-details__terms-link {
        margin-bottom: 15px;
        display: inline-block;
    }

    .offer-details__book-button {
        margin-bottom: 20px;
    }
