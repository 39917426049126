.hero-title {
    @include hero-title;
}

.page-title {
    @include page-title;
}

.large-title {
    @include large-title;
}

.medium-title {
    @include medium-title;
}

.small-title {
    @include small-title;
}

.tiny-title {
    @include tiny-title;
}

