.room-highlight {
	@include media(
		$l-layout: flag,
		$m-layout: flag,
		$s-layout: stack,
		$l-object-size: 33.3333%,
		$l-content-size: 66.6667%,
		$l-gutter-size: 20px
	);
}

.room-highlight__title {
	@include small-title;
}

.room-highlight__description {
	@include flush-last-child;
}
