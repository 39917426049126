.menu {
    text-align: center;
    background-color: $c-white;
    max-width: $max-content-width;
    margin: $u-large-spacer auto 0;
    box-shadow: 0 0 20px 5px rgba($c-border, 0.4);
    border-radius: $u-menu-border-radius;
    padding: 0 20px;
}

    .menu__section {
        margin: 0 auto;
        max-width: $max-content-width - 150px;
        padding-top: $u-large-spacer;
        padding-bottom: $u-large-spacer;
        border-bottom: 1px solid $c-menu-section-border;

        &:last-child {
            border-bottom: none;
        }
    }
        .menu__section__title {
            @include apply-map($f-menu-section-title);
            @include rem($u-menu-section-title);
            margin-bottom: 20px;
        }

    .menu__dish {
        margin-bottom: $u-med-spacer;
        position: relative;
        color: $c-menu-dish-title;

        &:last-child {
            margin-bottom: 0;
        }
    }
        .menu__dish__title {
            @include apply-map($f-menu-dish-title);
            @include rem($u-menu-dish-title);
            text-align: left;
            max-width: 80%;

        }
        .menu__dish__desc {
            @include rem($u-menu-dish-desc);
            font-style: italic;
            text-align: left;
            color: $c-menu-dish-desc;
            max-width: 80%;
        }
        .menu__dish__price {
            @include apply-map($f-menu-dish-title);
            @include rem($u-menu-dish-title);
            float: right;
        }

    .menu__pdf {
        margin-bottom: $u-med-spacer;

        &:last-child {
            margin-bottom: 0;
        }
    }
    .menu__smallprint {
        @include smallprint;

        small {
            font-size: inherit;
        }
    }
