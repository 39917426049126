html {
    font-size: 100%;
    height: 100%;
    text-size-adjust: 100%;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    height: 100%;
    background: white;
    background-color: $c-body-bg;
    @include rem($u-copy);
}

pre {
    padding: 1em;
    background-color: #EEE;
    margin-bottom: 15px;
}

::-moz-selection {
    background-color: $c-brand;
    color: #FFF;
    text-shadow: none;
}

::selection {
    background-color: $c-brand;
    color: #FFF;
    text-shadow: none;
}

.outer-wrapper {
    position: relative;
    text-align: left;
}

html #debug-bar {
    width: 100%;
}
