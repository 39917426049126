.address {
    text-align: center;
}
    .address__title {
        @include medium-title;
    }
    .address__address {
        margin-bottom: $u-med-spacer;
    }
    .address__line {
        @include intro;
        @include rem(14px);
        display: block;
    }
    .address__maps-link {
        @include smallcaps;
        @include underline;
    }
