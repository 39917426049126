.cookie-bar {
    font-size: $cookie-fontsize;
    background: $cookie-bg;
    z-index: 3000;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;

    &__wrap {
        margin: 0 auto;
        max-width: 90%;
        padding: 5px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;

        @media (max-width: 600px) {
            flex-wrap: wrap;
        }
    }

    &__copy {
        margin: 5px 10px;
        font-size: calc((12px / $cookie-fontsize) * 1em);
        flex: 1 1 auto;

        @media (max-width: 600px) {
            width: 100%;
        }
    }
    &__action {
        flex: 0 0 auto;
        margin: 5px 10px;

        &--preferences {
        }

        &--accept {
        }
    }
}

