
//social feed
//
.social-feed {
    background-color: $c-brand;
}

.social-post {
    @include aspect(1);
    display: inline-block;
    position: relative;
    border: 1px solid #fff;
    color: #fff;

    img {
        width: 100%;
    }

    .no-flexbox & {
        border: 0;
    }
}

    .social-post__username,
    .social-post__actions {
        position: absolute;
        left: 0;
        width: 100%;
    }

    .social-post__username {
        top: 0;
        padding: 10px;
        @include smallcaps;
        @include rem(12px);

        .icon {
            margin-right: 6px;

            @include breakpoint(tiny) {
                display: none;
            }
        }

        * {
            display: inline-block;
            vertical-align: middle;
        }

        @include breakpoint(mobile) {
            @include rem(11px);
        }

        .social-post--instagram & {
            text-shadow: 0 0 2px #000;
        }
    }
    .social-post__text {
        @include rem(13px);
        display: block;
        padding: 25px 10px;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        max-width: 190px;
        transform: translate(-50%, -50%);

        .no-flexbox & {
            position: relative;
            transform: none;
            top: 0;
            left: 0;
            padding-top: 50px;
            margin: 0 auto;
        }

        @include breakpoint(mobile) {
            @include rem(12px);
        }
        @include breakpoint(tiny) {
            @include rem(11px);
            padding-bottom: 10px;
        }
    }

    .social-post__actions {
        @include remove-white-space;
        bottom: 10px;
        padding: 0 5px;

        @include breakpoint(tiny) {
            display: none;
        }

        .icon {
            display: inline-block;
            vertical-align: baseline;
        }

        .no-flexbox & {
            position: relative;
            transform: none;
            bottom: 0;
            top: 0;
            left: 0;
            padding-top: 30px;
        }
    }

    .social-post__action {
        font-family: $f-title-stack;
        width: 33.3333%;
        text-align: center;
        display: inline-block;
        vertical-align: top;
        line-height: 1;
        height: 1em;
        overflow: hidden;
    }
        .social-post__action__title {
            @include breakpoint(tablet) {
                // display: block;
            }
        }

