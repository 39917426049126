.block--richtext {
    max-width: $section-intro-max-width-wide;
    text-align: center;

    .block__buttons {
        margin-top: to-rem(40px);
        display: flex;
        gap: to-rem(40px);
        justify-content: center;

        @include breakpoint(tablet) {
            gap: 30px;
            margin-top: to-rem(25px);
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }
    }

    .block__buttons--only-buttons {
        margin: 0;
        padding-left: calc((1 / 12) * 100%);
        padding-right: calc((1 / 12) * 100%);
    
        @media screen and (min-width: 1600px) {
            padding: 0;
        }
    
        @include breakpoint(phablet) {
            padding: 0;
        }
    }

    .block__buttons--single {
        @include breakpoint(tablet) {
            flex-direction: row;
            justify-content: center;
        }
    }
}
