.button--light {
    border-color: $c-secondary;
    color: $c-secondary;
}

.button--fixed-mobile {
    @include breakpoint(phablet) {
        position: fixed;
        bottom: to-rem(15px);
        left: to-rem($site-gutter--mobile);
        right: to-rem($site-gutter--mobile);
        background-color: var(--foreground);
        color: var(--background);
        border-color: var(--foreground);
        width: calc(100% - (2 * $site-gutter--mobile));
        z-index: 10;
    
        &:hover {
            color: var(--background);
            background-color: var(--foreground);
        }

        &.active {
            background-color: $c-secondary;
            border-color: $c-secondary;
            color: $c-brand;
        }

        [data-scroll-lock-locked="true"] & {
            background-color: $c-brand;
            border-color: $c-brand;
            color: $c-secondary;
        }
    }
}

@keyframes growBorder {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

.button--border-bottom {
    position: relative;
    border: 0;
    padding: 0;
    padding-bottom: to-rem(10px);

    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        background-color: $c-brand;
        width: 100%;
    }

    &:hover {
        background-color: transparent;
        color: $c-brand;
    }

    &:hover::after {
        animation: growBorder 400ms ease forwards;
    }
}

.button-full-mobile {
    @include breakpoint(phablet) {
        width: 100%;
    }
}

.button-full-mobile {
    @include breakpoint(phablet) {
        width: 100%;
    }
}

.button--wrap {
    white-space: normal;
    line-height: 1.3;
}

.button--wide {
    padding-left: to-rem(60px);
    padding-right: to-rem(60px);
}
