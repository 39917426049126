// Justify is a simple asbtracted pattern/object for positioning two elements with the remaining space 'between' them
// @author Brendan Patterson <brendan@d3r.com>

.justify {
    display: block;
    width: 100%;
    text-align: justify;
    text-justify: distribute; // ie fix
    @include remove-white-space;
    // Use psudo element to force a line wrap necessary for text-align justify to work
    &:after {
        content: "";
        display: inline-block;
        width: 95%;
        height: 0;
    }
}

.justify__item {
    display: inline-block;
    width: auto;
    text-align: left;
    &:nth-of-type(1) {
        padding-right: 10px;
    }
    &:nth-of-type(2) {
        padding-left: 10px;
    }
}

#{$md-flexbox} {
    .justify {
        display: flex;
        flex: 1 1 auto;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        // Undo unnecessary styling from non flexbox version below
        text-align: left;
        &:after {
            content: none;
            display: none;
        }
    }
}

#{$md-flexbox} {
    .justify__item {
        // No changes
    }
}
