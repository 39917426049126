.section-intro {
    max-width: $section-intro-max-width;
    letter-spacing: 0.5px;

   a.button,
   button.button {
        margin-top: to-rem(35px);

        @include breakpoint(tablet) {
            margin-top: to-rem(20px);
        }
    }

    .richtext {
        margin-top: to-rem(20px);

        &:first-child {
            margin-top: 0;
        }
    }

    .richtext--title {
        margin-top: 0;

        @include breakpoint(tablet) {
            text-align: left;
        }
    }
}

.section-intro--left {
    text-align: left;
}
.section-intro--no-max {
    max-width: none;
}

.section-intro--wide {
    max-width: $section-intro-max-width-wide;
}

.section-intro--mob-center {
    .richtext--title {
        @include breakpoint(tablet) {
            text-align: center;
        }
    }
}

.section-intro--narrow {
    max-width: none;
    padding-left: calc((1 / 12) * 100%);
    padding-right: calc((1 / 12) * 100%);

    @include breakpoint(tablet) {
        padding: 0;
    }
}

.section-intro--inline {
    display: flex;
    max-width: none;
    gap: to-rem(20px);
    text-align: left;

    @include breakpoint(tablet) {
        flex-wrap: wrap;
    }

    a.button,
    button.button {
        margin-top: to-rem(20px);
    }
}

    .section-intro__left {
        flex-basis: 40%;
        flex-shrink: 0;

        @include breakpoint(tablet) {
            flex-basis: 100%;

            .section-intro--mob-center & {
                text-align: center;
            }
        }
    }

        .section-intro__title {
            position: relative;
            width: fit-content;
            margin-left: auto;
            margin-right: auto;

            .section-intro--inline & {
                margin: 0;

                @include breakpoint(tablet) {
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            @include breakpoint(tablet) {
                margin-bottom: to-rem(7px);
                text-align: left;

                .section-intro--mob-center & {
                    text-align: center;
                }
            }
        }


        .section-intro__title--large {
            @include font(45px, 40px);

            letter-spacing: 3px;

            @include breakpoint(tablet) {
                @include font(30px, 35px);

                letter-spacing: 2px;
            }
        }

            .section-intro__animation {
                position: absolute;
                opacity: 0;
                transition: opacity 200ms ease-in;
                z-index: 5;
                width: to-rem(275px);
                max-width: to-rem(275px);
            }

            .section-intro__animation--dining {
                right: 0;
                transform: translateX(calc(-100% - 115px));
                top: to-rem(-70px);

                // &.section-intro__animation--hide-desktop {
                //     transition-delay: 750ms;
                //     left: 0;
                //     right: initial;
                //     transform: translateX(calc(-100% + 75px));
                //     width: to-rem(150px);
                //     max-width: to-rem(150px);
                //     top: to-rem(-30px);
                // }
            }

            .section-intro__animation--rooms {
                transform: translateX(calc(-100% + 45px));
                top: to-rem(-35px);

                &.section-intro__animation--hide-desktop {
                    transform: translateX(calc(-100% + 25px));
                    width: to-rem(90px);
                    max-width: to-rem(90px);
                    top: to-rem(-5px);
                }
            }

            .section-intro__animation--active {
                opacity: 1;
            }

            .section-intro__animation--hide {
                display: none;
            }

            .section-intro__animation--hide-mobile {
                @include breakpoint(tablet) {
                    display: none;
                }
            }

            .section-intro__animation--hide-desktop {
                display: none;

                @include breakpoint(tablet) {
                    display: block;
                }
            }

        .section-intro__details {
            margin-top: -5px;

            span {
                @include apply-map($f-primary);
                @include font(10px, 28px);

                position: relative;
                text-transform: uppercase;
                letter-spacing: 0.5px;

                &:not(:last-child) {
                    margin-right: to-rem(20px);

                    &::before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        right: to-rem(-15px);
                        transform: translateY(-50%) rotate(45deg);
                        background-color: $c-brand;
                        height: 6px;
                        width: 6px;
                    }
                }
            }
        }

        .section-intro__action {
            margin-top: to-rem(15px);
            display: flex;
            gap: to-rem(25px);

            @include breakpoint(tablet) {
                justify-content: center;
            }
        }

        .section-intro__action--mob-only {
            display: none;

            @include breakpoint(phablet) {
                display: flex;
            }
        }

    .section-intro__right {

        @include breakpoint(tablet) {
            .section-intro--mob-center & {
                text-align: center;
            }

            p {
                @include rem(14px);
            }
        }
    }

    .section-intro__longcopy {
        .section-intro--inline & {
            flex-basis: 60%;

            @include breakpoint(tablet) {
                flex-basis: 100%;
            }
        }

        @include breakpoint(tablet) {
            text-align: left;

            .section-intro--mob-center &,
            .section-intro--offers & {
                text-align: center;
            }
        }
    }

    .section-intro__button {
        margin: to-rem(10px) auto 0;
        position: relative;
        width: fit-content;
    }
