:root {
    --swiper-pagination-color: #9d2235;
    --swiper-pagination-bullet-size: 10px;
    --swiper-pagination-bullet-width: 10px;
    --swiper-pagination-bottom: -40px;
    --swiper-pagination-bullet-horizontal-gap: 8px;
}

@import 'swiper/swiper-bundle';

.swiper-pagination {
    display: none;

    @include breakpoint(phablet) {
        display: block;
    }
}
