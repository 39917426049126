body {
    @include copy;
}

.intro {
    @include intro;
}

.copy {
    @include copy;
}

.smallprint {
    @include smallprint;
}
