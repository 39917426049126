@use 'sass:math';

.image-overlay {
    position: relative;
}

    .image-overlay__overlay {
        padding: $grid-gutter-v $grid-gutter-h;
        text-align: center;
        background: change-color($c-brand, $alpha: 0.7);
        color: $c-background;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        transition: opacity 200ms ease-in-out;

        > * {
            color: inherit;
        }

        .image-overlay:hover & {
            opacity: 1;
        }
    }

    .image-overlay__image {
        @include aspect(math.div(7, 5));
    }
