.link-list-bar {
    text-align: center;
    padding: $u-link-list-bar-padding-top 0 $u-link-list-bar-padding-bottom;

    .band > .wrap > &:first-child {
        padding-top: 0;
    }
    .section-intro &:last-child {
        padding-bottom: 0;
    }
}

    .link-list-bar__item {
        display: inline;

        &:before {
            content: "|";
            margin: 0 $u-link-list-bar-spacing;
        }
        &:first-child:before {
            display: none;
        }
    }

    .link-list-bar__link {
        @include smallcaps;
        color: $c-brand;
        &:hover {
            text-decoration: underline;
        }
    }

    .link-list-bar__link--active {
        color: $c-title;
        cursor: default;
        &:hover {
            text-decoration: none;
        }
    }

