////
/// Main Stylesheet for responsive pages
///
/// @author Dom Morgan <dom@d3r.com>
/// @author David Fox <david@d3r.com>
///

$ie8-support: false;
$debug: true !default;

/* ------------ Settings/Theme ------------ */

// Local theme to override default hospitality/settings. Done first beacause a lot
// of the variables in hospitality/settings rely on each other so we need to define them
// properly first
@import 'theme/fonts';
@import 'theme/variables';
@import 'theme/breakpoints';

// From hospitality
@import 'settings';

/* ------------ Tools ------------ */

// From hospitality
@import 'tools';

// Local files
@import 'tools/functions';
@import 'tools/mixins';

/* ------------ Generic ------------ */

// From hospitality
@import 'generic';

// Local files

/* ------------ Base ------------ */

// From hospitality
@import 'base';

// Local files
@import 'base/document';
@import 'base/buttons';
@import 'base/typography';
@import 'base/images';
@import 'base/keyframes';

/* ------------ Objects ------------ */

// From hospitality
@import 'objects';

// Local files
@import 'objects/wraps';
@import 'objects/fields';
@import 'objects/rule';
@import 'objects/grid';

/* ------------ Components ------------ */

// From hospitality
@import 'components';

// Local files
@import 'components/footer';
@import 'components/home-hero';
@import 'components/header';
@import 'components/header-menu';
@import 'components/section-intro';
@import 'components/band';
@import 'components/block';
@import 'components/listing';
@import 'components/link-list-bar';
@import 'components/band-tabs';
@import 'components/map';
@import 'components/image-overlay';
@import 'components/swiper';
@import 'components/gallery';
@import 'components/overlay';
@import 'components/booking-modal';
@import 'components/hero';
@import 'components/gallery-block';
@import 'components/dialog';
@import 'components/offer-details';
@import 'components/additional-rooms';
@import 'components/room-index';
@import 'components/widget';
@import 'components/centred-form';
@import 'components/two-col-sections';
@import 'components/blocks';
@import 'components/dining-intro';
@import 'components/page-anchors';
@import 'components/richtext';
@import 'components/simple-overview';
@import 'components/action-bar';
@import 'components/address';
@import 'components/hero-logo';
@import 'components/cookie-centre';
@import 'components/d3r-popups';
@import 'components/spinner';

/* ------------ Trumps ------------ */

// From hospitality
@import 'trumps';

// Local files
@import 'trumps/page-background';
@import 'trumps/sal';

/* ------------ Remove/re-factor before live (below) ------------ */

// stylguide bodge
.styleguide-view-title {
    &,
    & a {
        font-weight: bold;
        color: lighten(black, 65%);
        text-transform: uppercase;
        margin: 20px 5px;
        font-size: 14px;
    }
}

#debug-bar {
    z-index: 10;
}
