
////
/// Default richtext
///
/// @author Dom Morgan <dom@d3r.com>

.richtext {
    //font-family: $f-richtext-stack;
    @include rem($base-px);
    @include flush-last-child;
    @include apply-map($f-richtext);

    a {
        color: $c-richtext-link;

        &:hover {
            text-decoration: underline;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @include apply-map($f-richtext-title);
        color: $c-richtext-title;
    }

    h1 {
        @include rem(map-get($f-richtext-sizes, h1));
    }

    h2 {
        @include rem(map-get($f-richtext-sizes, h2));
    }

    h3 {
        @include rem(map-get($f-richtext-sizes, h3));
    }

    h4 {
        @include rem(map-get($f-richtext-sizes, h4));
    }

    h5 {
        @include rem(map-get($f-richtext-sizes, h5));
    }

    h6 {
        @include rem(map-get($f-richtext-sizes, h6));
    }

    p {
        @include rem($u-richtext-copy);
        @include apply-map($f-richtext-copy);
        color: $c-richtext-copy;
        margin-bottom: 1em;
    }

    blockquote {
        @include apply-map($f-richtext-alt);
        @include rem($u-richtext-blockquote);
        padding: 1em 2em;
        margin: 0.5em 0 1em;
        font-style: italic;
        line-height: 1.4;

        cite {
            display: block;
        }
    }

    ul,
    ol {
        //@include copy;
        @include rem($u-richtext-copy);
        padding: 0;
        margin: 0 0 1em;
    }

    ul ul,
    ol ol {
        font-size: 1em;
        margin-top: 0.3em;
    }

    ul {
        list-style: disc inside;
    }

    ol {
        list-style: decimal outside;
        padding-left: 1.1rem;

        ol {
            list-style-type: lower-alpha;

            ol {
                list-style-type: lower-roman;
            }
        }
    }

    li {
        margin: 0 0 0.5em;
    }
    ul li {
        @include list-item(wrap);
    }
    ol li {
        list-style: number outside;
    }

    dl {
        margin-bottom: 9px;
        padding-bottom: 1px;
    }

        dt {
            float: left;
            clear: both;
            padding-right: 5px;
        }

        dd {
            text-align: right;
            margin-bottom: 10px;
        }

}

.richtext.smallprint {
    @include smallprint;
    p {
        @include smallprint;
    }
    ul,
    ol {
        @include rem($u-smallprint);
        margin: 0 0 1em;
    }

    ul ul,
    ol ol {
        font-size: 1em;
        margin-top: 0.5em;
    }

    li {
        margin: 0 0 0.5em;
    }
}
