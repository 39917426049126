
// ==========================================================================
// VARIABLES
// ==========================================================================


//
// Base colors
//
$pickadate__blue: #0089ec !default;
$pickadate__blue-hover: #b1dcfb !default;
$pickadate__black: #000 !default;
$pickadate__white: #fff !default;


//
// Backgrounds
//
$pickadate__bg-white: $pickadate__white !default;
$pickadate__bg-grey-light: #f2f2f2 !default;


//
// Borders
//
$pickadate__border-grey: #777 !default;
$pickadate__border-grey-light: #ddd !default;
$pickadate__border-select: darken( $pickadate__border-grey-light, 15% ) !default;


//
// Buttons
//
$pickadate__clear-red: #e20 !default;





//
// Picker base
//

// Make sure nothing is above the picker.
$pickadate__picker-z-index: 10000 !default;

// Animation speeds.
$pickadate__speed-animate-in: .15s !default;

// Focused input border color.
$pickadate__input-active-border: $pickadate__blue !default;

// Typography.
$pickadate__base-font-size: 16px !default;
$pickadate__base-line-height: 1.2 !default;

// Corners.
$pickadate__picker-border-radius: 5px !default;

// Drop shadows.
$pickadate__picker-box-shadow: 0 12px 36px 16px rgba(0,0,0,.24) !default;
$pickadate__picker-box-shadow-light: 0 6px 18px 1px rgba(0,0,0,.12) !default;

// Height breakpoints.
$pickadate__breakpoint-tiny: 26.5em !default; // 424px $pickadate__ 16px
$pickadate__breakpoint-small: 33.875em !default; // 542px $pickadate__ 16px
$pickadate__breakpoint-medium: 40.125em !default; // 642px $pickadate__ 16px
$pickadate__breakpoint-large: 46.75em !default; // 748px $pickadate__ 16px

// Width breakpoints.
$pickadate__breakpoint-width-tiny: 24.5em !default; // 392px $pickadate__ 16px




//
// Date picker options
//

// The year and weekday labels.
$pickadate__year-weekday-label: #999 !default;

// “Today” tag indicators.
$pickadate__blue-tag: #0059bc !default;
$pickadate__disabled-tag: #aaa !default;

// Disabled things.. such as days, month nav, etc.
$pickadate__disabled-things-bg: #f5f5f5 !default;
$pickadate__disabled-things-text: #ddd !default;
$pickadate__disabled-highlighted-things-bg: #bbb !default;





//
// Theme configurations
//

// The “default” min & max widths.
$pickadate__picker-min-width: 256px !default;
$pickadate__picker-max-width: 666px !default;

// The time picker min & max widths.
$pickadate__time-min-width: $pickadate__picker-min-width !default;
$pickadate__time-max-width: 320px !default;

// The “classic” theme settings.
$pickadate__classic-max-width: $pickadate__picker-max-width - 200px !default;
$pickadate__classic-min-width: $pickadate__picker-min-width - 80px !default;
$pickadate__classic-max-height: 25em !default;
$pickadate__classic-box-shadow: 0 6px 18px 1px rgba(0,0,0,.12) !default;








// ==========================================================================
// MIXINS
// ==========================================================================


//
// Common picker item states
//

// Highlighted.
@mixin picker-item-highlighted()
{
    border-color: $pickadate__blue;
}

// Hovered.
@mixin picker-item-hovered()
{
    cursor: pointer;
    color: $pickadate__black;
    background: $pickadate__blue-hover;
}

// Selected.
@mixin picker-item-selected()
{
    background: $pickadate__blue;
    color: $pickadate__white;
}

// Disabled.
@mixin picker-item-disabled()
{
    background: $pickadate__disabled-things-bg;
    border-color: $pickadate__disabled-things-bg;
    color: $pickadate__disabled-things-text;
    cursor: default;
}




//
// Opacity
//
@mixin opacity( $pickadate__decimal )
{
    $pickadate__percent: $pickadate__decimal * 100;
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=$pickadate__{percent})";
    filter: #{"alpha(opacity=$pickadate__{percent})"};
    -moz-opacity: $pickadate__decimal;
         opacity: $pickadate__decimal;
}



