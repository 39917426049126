.listing {
    text-align: center;
}

.listing__image {
    display: block;
    margin-bottom: 10px;
    img {
        @include fill-image;
    }
}

.listing__title {
    @include apply-map($f-listing-title);
    @include rem($f-listing-title-size);
    margin-bottom: 8px;
}

.listing__meta {
    @include apply-map($f-listing-meta);
    @include rem($f-listing-meta-size);
    margin-bottom: 8px;
}

.listing__category {
    @include category;
    margin-bottom: 8px;
}

.listing__description {
    @include copy;
    margin-bottom: 8px;
}

.listing__action {
    margin-top: 15px;
    text-transform: uppercase;
    font-weight: bold;
}

.listing__price,
.listing__secondary-cta,
.listing__cta {
    @include smallcaps;
    margin-right: 0.5em;
    &:last-child {
        margin-right: 0;
    }
}

.listing__cta {
    color: $c-brand;
    &:hover {
        text-decoration: underline;
    }
}

// Listing variations

.listing--neighbourhood {
    text-align: left;
}

.listing--room {

}
