.block {
    max-width: $max-content-width;
    margin-left: auto;
    margin-right: auto;
    // margin-bottom: $u-large-spacer;
}

.block--inline-image {
    @include clearfix;

    .block__figure {
        padding-bottom: $grid-gutter-v;
        width: 50%;
    }

    &.block--align-left {
        .block__figure {
            float: left;
            padding-right: $grid-gutter-h;
        }
    }

    &.block--align-right {
        .block__figure {
            float: right;
            padding-left: $grid-gutter-h;
        }
    }
}

.block--quote {
    text-align: center;
}
    .block__quote {
        @include apply-map($f-block-quote);
        @include rem($u-block-quote);
        font-style: italic;
        color: $c-block-quote;
        quotes: "\201C""\201D""\2018""\2019";

        &:before {
            content: open-quote;
        }
        &:after {
            content: close-quote;
        }
    }
    .block__source {
        @include apply-map($f-block-source);
        @include rem($u-block-source);
        margin-top: $u-small-spacer;
        display: block;
        color: $c-block-source;
    }

.block--hero,
.block--subpage {
    max-width: none;
}

.block--gallery {
    .gallery + .richtext {
        margin-top: $u-spacer;
    }
}

.block__downloads {
    margin-top: $u-spacer;

}
.downloads {

}
    .downloads__title {
        @include category;
        margin-bottom: $u-med-spacer;
    }
    .downloads__download {
        color: $c-download-desc;
        @include apply-map($f-download-desc);
        @include rem($f-download-desc-size);
    }

