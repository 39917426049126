@use 'sass:math';

.hero {
    position: relative;
    width: 100%;

    &::before {
        content: '';
        float: none;
        padding: 0;
        margin: 0;
        position: absolute;
        left: 0;
        top: 0;
        height: to-rem(330px);
        width: 100%;
        opacity: 0.75;
        background: linear-gradient(180deg, #040303 0%, rgba(0,0,0,0) 100%, rgba(0,0,0,0) 100%);
        z-index: 2;
    }
}

    .hero__image {
        @include aspect(math.div(8, 3));
    }
    .hero__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        svg {
            width: 250px;
            max-width: 80%;
            height: auto;
            fill: $c-secondary;

            @include breakpoint(phablet) {
                width: 150px;
            }
        }
    }

.hero--overlay {
    &::after {
        content: '';
        float: none;
        padding: 0;
        margin: 0;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: $c-black;
        opacity: 0.4;
    }
}

.hero--tall {
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);

    .hero__image {
        height: 100%;
        position: relative;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
