.cookie-preferences {
    color: $cookie-fontcolor;

    * {
        box-sizing: border-box;
    }

    &:before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 3001;
        background: $cookie-overlay;
    }

    &__form {
        box-shadow: 0 0 25px rgba(0,0,0,0.5);
        background: $cookie-bg;
        border-radius: $cookie-radius;
        font-size: $cookie-fontsize;
        position: fixed;
        width: 600px;
        min-height: 350px;
        max-width: 90vw;
        max-height: 90vh;
        z-index: 3002;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        overflow: scroll;
    }


    &__title {
        flex: 0 0 100%;
        margin: 0;
        padding: 15px;
        font-size: calc((22px / $cookie-fontsize) * 1em);
    }

        &__nav {
            box-sizing: border-box;
            flex: 1 0 30%;
            background: $cookie-nav-bg;
        }
            &__nav-link {
                padding: 13px 15px;
                display: block;
                border-bottom: 1px solid $cookie-bg;
                color: $cookie-altfontcolor;

                &--active {
                    background: $cookie-fg;
                    color: $cookie-fontcolor;
                }
            }

        &__panels {
            box-sizing: border-box;
            flex: 2 0 70%;
            background: $cookie-fg;
        }

            &__panel {
                padding: 30px;
                display: none;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: stretch;

                &--active {
                    display: flex;
                }
            }
                &__panel-title {
                    flex: 1 0 auto;
                    vertical-align: middle;
                    margin: 0 0 0.5em;
                    font-size: calc((17px / $cookie-fontsize) * 1em);
                }
                &__panel-description {
                    flex: 1 0 100%;
                    border-top: 1px solid $cookie-border;
                    padding-top: 15px;
                    margin-top: 15px;
                }
                &__switch {
                    flex: 0 0 auto;
                    margin: 0 0 0 auto;
                    vertical-align: middle;

                    input {
                        display: none;
                    }
                }
                    &__switch-icon {
                        vertical-align: middle;
                        display: inline-block;
                        position: relative;
                        background: $cookie-switch-bg;
                        width: (2 * $cookie-switch-height) - 4px;
                        height: $cookie-switch-height + 2px;
                        border-radius: calc($cookie-switch-height / 2);
                        border: 1px solid $cookie-border;

                        &:before {
                            display: block;
                            position: absolute;
                            content: "";
                            top: 3px;
                            left: 3px;
                            width: $cookie-switch-height - 6px;
                            height: $cookie-switch-height - 6px;
                            border-radius: calc(($cookie-switch-height - 6px) / 2);
                            background: $cookie-switch-fg;
                        }
                    }
                    &__switch-label {
                        vertical-align: middle;
                        display: inline-block;

                        &:after {
                            content: attr(data-inactive);
                        }
                    }
                &__switch input:checked ~ &__switch-icon {
                    &:before {
                        left: auto;
                        right: 3px;
                    }
                }
                &__switch input:checked ~  &__switch-label {
                    &:after {
                        content: attr(data-active);
                    }
                }
                &__noswitch {
                    vertical-align: middle;
                    display: inline-block;
                    line-height: $cookie-switch-height + 2px;
                    margin: 0;
                }

        &__footer {
            padding: 15px;
            flex: 0 0 100%;
            background: $cookie-footer-bg;
            display: flex;
            align-items: center;

            button {
                margin-left: auto;
            }
        }
            &__statement {
                font-size: calc((12px / $cookie-fontsize) * 1em);
                color: $cookie-altfontcolor;
            }
            &__submit {
                font-size: calc((14px / $cookie-fontsize) * 1em);
            }
}

