// Social share

.social-actions {
    display: block;
}

.social-actions__title {
    @include apply-map($f-breadcrumb);
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
    margin-right: 10px;
}

.social-actions__items {
    display: inline-block;
    @include remove-white-space;
    vertical-align: middle;
}

.social-actions__item {
    margin-right: 10px;
    min-width: 20px;
    cursor: pointer;
}

.social-actions__item-content {
    display: block;
    text-align: center;
}

.social-actions__icon {
    vertical-align: middle;
}
