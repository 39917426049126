.simple-overview {
    text-align: center;
    max-width: $section-intro-max-width;
    margin: 0 auto;
}
.simple-overview__body {
    margin: $u-large-spacer 0;
}
.simple-overview__links {
    margin-bottom: $u-large-spacer;
}
    .simple-overview__link {
        display: block;
        margin-bottom: $u-med-spacer;

        a {
            @include smallcaps;
            @include underline;
        }
    }
.simple-overview__image {
    width: 673px;
    margin: 0 auto;
    max-width: 100%;
}
