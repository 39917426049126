.spa-highlight {
    @include media(
        $l-layout: flag,
        $m-layout: flag,
        $s-layout: stack,
        $l-object-size: 33.3333%,
        $l-content-size: 66.6667%,
        $l-gutter-size: 20px
    );
}

.spa-highlight__title {
    @include small-title;
}

.spa-highlight__description {
    @include flush-last-child;
}
