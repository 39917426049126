/// Set a relative font sizse and a unitless line height, based on the required line height and a font size
///
/// @param {Number} $fontSize Font size
/// @param {Number} $lineHeight Line height
/// @param {Bool} $em Use re
/// @author Joe Adcock <joe@d3r.com>

@mixin font($fontSize, $lineHeight, $em: false) {
    @if $em {
        font-size: calc(1em * $fontSize / $base-px);
    } @else {
        @include rem-new($fontSize);
    }

    line-height: calc($lineHeight / $fontSize);
}
