// This is the main media object block for the features section
.features {
	@include media(
		$l-layout: flag,
		$m-layout: flag,
		$s-layout: stack,
		$l-object-size: 40%,
		$l-content-size: 60%,
		$l-gutter-size: $u-spacer
	);
}

// Each set of lists/information is contained within a group
.features__group {
	margin-bottom: 20px;
}

.features__title {
	@include small-title;
}

// 2 column list
.features__features-list {
	column-count: 2;
	column-gap: $u-spacer;
	@include breakpoint(tablet) {
		column-count: 1;
	}
}

.features__features-list-item {
	-webkit-column-break-inside: avoid; // Webkit
	page-break-inside: avoid; // Firefox
	@include list-item(nowrap);
}
