@use 'sass:math';

.map__grid {
    display: grid;
    grid: auto / 3fr 7fr;
    gap: to-rem($grid-gutter-h);
    scroll-margin-top: calc($u-header-height + 100px);

    @include breakpoint(tablet) {
        grid-template-columns: 1fr;
    }

    @include breakpoint(phablet) {
        scroll-margin-top: calc($u-header-height-mobile + 80px);
    }
}

    .map__holder {
        height: 750px;

        @include breakpoint(tablet) {
            height: 465px;
        }
    }

    .map__places {
        @include hideScrollBar;

        max-height: 750px;
        overflow-y: scroll;
        padding-top: to-rem(30px);
        border-top: 1px solid $c-border;

        @include breakpoint(tablet) {
            order: 1;
            overflow: auto;
            max-height: none;
            padding-top: to-rem(20px);
        }
    }

    .map__places--active {
        border-bottom: 1px solid $c-border;

        @include breakpoint(tablet) {
            border-bottom: none;
        }
    }

        .map__category {
            display: grid;
            grid-template-rows: auto 0fr;
            transition: grid-template-rows 300ms ease;
            justify-content: start;
            margin: 2px 0;
        }

        .map__category--active {
            grid-template-rows: auto 1fr;
        }

            .map__list {
                overflow: hidden;
                transition: margin 150ms ease;

                .map__category--active & {
                    margin-top: to-rem(25px);
                }
            }

                .map__list-item {
                    display: flex;
                    align-items: center;
                    gap: to-rem(20px);
                    min-height: to-rem(31px);

                    &:not(:last-child) {
                        margin-bottom: to-rem(15px);
                    }
                    
                    .map__category--active & {
                        &:last-child {
                            margin-bottom: to-rem(20px);
                        }
                    }

                    span  {
                        position: relative;
                        display: grid;
                        place-items: center;
                        color: $c-secondary;
                        min-width: to-rem(31px);

                        &::before {
                            content: '';
                            position: absolute;
                            width: to-rem(22px);
                            height: to-rem(22px);
                            transform: rotate(45deg);
                            background-color: $c-brand;
                            letter-spacing: 1px;
                            z-index: -1;
                        }
                    }
                }

                    button.map__list-button {
                        @include apply-map($f-primary);
                        @include font(14px, 16px);
                        
                        padding: 0;
                        border: none;
                        border-bottom: 1px solid transparent;
                        letter-spacing: 0.5px;
                        font-weight: 400;
                        text-transform: none;
                        padding: 4px 0;
                        transition: border-color 150ms ease;

                        &:hover {
                            background-color: transparent;
                            color: $c-brand;
                            border-color: $c-brand;
                        }
                    }

                    button.map__list-button--active {
                        font-weight: 700;
                    }

        .map__title {
            @include apply-map($f-title);
            @include font(12px, 12px);
        
            letter-spacing: 1.5px;
            display: inline-block;
            margin: 3px 0;
            padding: 7px 0;
            color: $c-title;
            font-weight: 400;
            border-bottom: 1px solid transparent;
            transition: border 150ms ease;
            width: fit-content;

            .map__category--active & {
                border-color: $c-brand;
                font-weight: 700;
            }
        
            &:hover {
                border-color: $c-brand;
                color: $c-brand;
                text-decoration: none;
            }
        }

            .map__title-button {
                padding: 0;
                border: 0;
                font-weight: inherit;

                &:hover {
                    background-color: transparent;
                    color: $c-brand;
                }
            }

            .map__title-button--has-icon {
                display: flex;
                gap: to-rem(10px);

                svg {
                    fill: $c-brand;
                    width: 13px;
                    transition: transform 150ms ease;

                    .map__category--active & {
                        transform: rotate(180deg);
                    }
                }
            }

.map__infowindow {
    padding: 0;
    transition: opacity 150ms ease;
}

.map_infowindow__details {
    float: none;

    .listing__details {
        overflow: hidden;
        color: $c-secondary;
        padding: 0;
        &::before,
        &::after {
            content: none;
        }
    }
}

// Infowindow
.gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-tc::after {
    background: $c-brand;
}

.gm-style-iw.gm-style-iw-c {
    min-width: to-rem(260px) !important;
    max-width: none !important;
}

//infowindow close button
.gm-ui-hover-effect > span {
    background-color: $c-secondary;
}
