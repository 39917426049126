.richtext {
    color: $c-richtext-copy;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.4;
        &:not(:first-of-type) {
            margin-top: to-rem(50px)
        }
    }

    a {
        text-decoration: underline;
    }

    ul {
        text-align: left;

        li {
            padding-left: 20px;
            margin-bottom: to-rem(4px);

            &::before {
                content: '';
                position: absolute;
                top: 8px;
                left: 0;
                transform: rotate(45deg);
                background-color: $c-brand;
                height: 6px;
                width: 6px;
                border-radius: 0;
            }
        }
    }
}

    .richtext__button {
        margin-top: to-rem(30px);
        display: flex;
        justify-content: center;
    }

.richtext--flush-p {
    p {
        margin: 0;
    }
}

.richtext--title p {
    @include apply-map($f-title);
    @include font(16px, 28px);

    letter-spacing: 0.5px;
    text-transform: none;

    @include breakpoint(tablet) {
        @include font(14px, 24px);
    }

    &:not(:last-child) {
        margin-bottom: to-rem(25px);
    }
}

.richtext--center {
    text-align: center;
}
