.block--illustration {
    text-align: center;
    max-width: $section-intro-max-width;

    .block__svg {
        margin: 0 auto $grid-gutter-v;
        width: 160px;

        svg {
            width: 100%;
            height: auto;
        }
    }

    .block__text {
        text-align: left;
    }
}
