
.masonry {
    min-height: 50vh;
    margin-right: -$grid-gutter-h--tight;
}

    .masonry:after {
        content: '';
        display: block;
        clear: both;
    }

    .masonry__sizer,
    .masonry__item {
        width: 25%;

        @include breakpoint(tablet) {
            width: 33.333%;
        }

        @include breakpoint(mobile) {
            width: 50%;
        }
    }

    .masonry__item--large {
        width: 50%;

        @include breakpoint(tablet) {
            width: 66.6666%;
        }

        @include breakpoint(tablet) {
            width: 100%;
        }
    }

    .masonry__item {
        float: left;
        background-size: cover;
        background-position: center center;
        padding-right: $grid-gutter-h--tight;
        padding-bottom: $grid-gutter-v--tight;
    }

    .masonry__image {
        width: 100%;
        display: block;
    }

