.cookie-audit__form {
    width: 100%;
    box-sizing: border-box;

    th {
        font-weight: bold;
    }
}
    .cookie-audit__cookies-col {
        width: 25%;
    }
    .cookie-audit__name {
        font-weight: bold;
        width: 75%;
    }

.cookie-list,
.richtext .cookie-list {
    margin-bottom: 0;

    li {
        margin-bottom: 0;
        padding: 0;

        &::before {
            display: none;
        }
    }
}
