@use 'sass:math';

.block--inline-image {
    @include clearfix;

    display: grid;
    grid: auto / 2fr 1fr;
    gap: to-rem($grid-gutter-h);

    @include breakpoint(tablet) {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .block__figure {
        @include aspect(math.div(4, 3));

        padding: 0;
        width: initial;

        @include breakpoint(tablet) {
            order: 1;
            margin-top: to-rem(40px);
        }
    }
    
    .block__text {
        padding-right: calc((1 / 4) * 100%);

        @include breakpoint(tablet) {
            padding: 0;
        }
    }

    &.block--align-left {
        grid-template-columns: 1fr 2fr;

        @include breakpoint(tablet) {
            grid-template-columns: 1fr
        }
        
        .block__figure {
            padding: 0;
            float: none;
            grid-column: 2 / span 1;

            @include breakpoint(tablet) {
                grid-column: 1 / span 1;
            }
        }

        .block__text {
            grid-row: 1 / span 1;
            padding-right: 0;
            padding-left: calc((1 / 4) * 100%);

            @include breakpoint(tablet) {
                padding: 0;
                grid-row: 2 / span 1;
            }
        }
    }

    &.block--align-right {
        .block__figure {
            @include breakpoint(tablet) {
                padding: 0;
            }
        }
    }
}
