.booking-modal {
    min-height: 100vh;
    height: 100%;
    background-color: $c-body-bg;
    max-width: to-rem(430px);
    transition: transform 150ms ease-out;
    transform: translateX(100%);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    pointer-events: none;

    @include breakpoint(phablet) {
        max-width: 100%;
    }

    &::before {
        content: '';
        position: absolute;
        width: 100vw;
        height: 100%;
        right: 100%;
        background-color: $c-black;
        transition: opacity 100ms ease;
        opacity: 0;

        @include breakpoint(phablet) {
            display: none;
        }
    }
}

.booking-modal--active {
    transform: translateX(0);
    pointer-events: initial;

    &::before {
        opacity: 0.5;
    }
}

    .booking-modal__close {
        @include font(16px, 20px);

        letter-spacing: 2px;
        color: $c-brand;
        padding: 0;
        border: none;
        height: to-rem(45px);
        margin: to-rem(25px) 0;
        padding: 0 to-rem($site-gutter);
        width: 100%;
        text-align: right;

        &:hover {
            background-color: transparent;
            color: $c-brand;
        }
    }

    .booking-modal__title {
        @include medium-title;
        @include font(22px, 30px);

        margin-bottom: to-rem(20px);
    }

    .booking-modal__content {
        max-width: 300px;
        padding: 0 15px;
        margin-left: auto;
        margin-right: auto;

        .button {
            margin-top: to-rem(10px);
        }
    }

    .booking-modal__tab-wrap {
        display: flex;
        gap: to-rem(15px);
        margin-bottom: to-rem(30px);
    }

    .booking-modal__tab {
        @include smallcaps;

        padding: 0;
        border: none;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            height: 1px;
            background-color: $c-brand;
            width: 0;
        }

        &:hover {
            background-color: transparent;
            color: $c-brand;
        }

        &:hover::after {
            animation: growBorder 400ms ease forwards;
        }
    }

    .booking-modal__tab--active {
        &::after {
            width: 100%;
        }

        &:hover::after {
            animation: none;
        }
    }

    .booking-modal__tab-content--hidden {
        display: none;
    }

.no-js div[id$=guests_combined_wrap] {
    display: none;
}
div[id$=child_wrap],
div[id$=adult_wrap] {
    display: none;

    .no-js & {
        display: block;
    }
}
