$u-gallery-gutter: 2% !default;
$u-gallery-aspect: $u-aspect-landscape !default;

/// |<------- A ------->|<- g ->|<--- B --->|
///
/// aspect ratio r
///
/// A + B = 1 - g
/// A/r + g = 2B/r => A - 2B = gr
///
/// 3A = 2 - g(2 + r)
/// 3B = 1 - g(1 - r)
///
/// @author Dom Morgam <dom@d3r.com>

.gallery {
    overflow: hidden;
}
    .gallery__figure {
        @include aspect($u-aspect-landscape);
        float: left;
        position: relative;
    }

    .gallery__figure--full {
        float: none;

        img {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .gallery__figure--featured {
        width: calc((200% - $u-gallery-gutter * (2 - $u-gallery-aspect)) / 3);
        margin-right: $u-gallery-gutter;

        @include breakpoint(mobile) {
            width: 100%;
            margin-right: 0;
            margin-bottom: $u-gallery-gutter;
        }
    }
    .gallery__figure--additional {
        width: calc((100% - $u-gallery-gutter * (1 + $u-gallery-aspect)) / 3);
        margin-bottom: $u-gallery-gutter;

        @include breakpoint(mobile) {
            width: calc((100% - $u-gallery-gutter) / 2);
            margin-right: $u-gallery-gutter;
            margin-bottom: 0;
        }

        .gallery__figure--additional + & {
            margin-bottom: 0;
            margin-right: 0;
        }
    }
        .gallery__more {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.5);
            transition: background-color 0.3s;
            text-align: center;

            .gallery:hover & {
                background-color: rgba(0, 0, 0, 0.7);
            }

            span {
                @include absolute-center;
                @include apply-map($f-gallery);
                color: $c-gallery;
            }
        }
