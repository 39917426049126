// Make an element the full browser height.
@mixin full-height($property: 'height') {
    #{$property}: 100vh; /* Fallback for browsers that do not support Custom Properties */
    #{$property}: calc(var(--vh, 1vh) * 100); /* Magic trick to make vh work on mobile browsers - fallback if we don't support svh */
    #{$property}: 100svh;

    .body--debug & {
        #{$property}: calc(100vh - 20px);
        #{$property}: calc((var(--vh, 1vh) * 100) - 20px);
        #{$property}: calc(100svh - 20px);
    }

    .body--admin & {
        #{$property}: calc(100vh - 44px);
        #{$property}: calc((var(--vh, 1vh) * 100) - 44px);
        #{$property}: calc(100svh - 44px);
    }

    .body--debug.body--admin & {
        #{$property}: calc(100vh - 64px);
        #{$property}: calc((var(--vh, 1vh) * 100) - 64px);
        #{$property}: calc(100svh - 64px);
    }
}
