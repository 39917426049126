.page-background--broadwick-pink {
    --background: #{$c-broadwick-pink};
    --foreground: #{$c-brand};
    --swiper-pagination-color: #{$c-brand};
}
.page-background--bar-jackie {
    --background: #{$c-bar-jackie};
    --foreground: #{$c-secondary};
    --swiper-pagination-color: #{$c-secondary};
}
.page-background--dear-jackie {
    --background: #{$c-dear-jackie};
    --foreground: #{$c-dear-jackie-secondary};
    --swiper-pagination-color: #{$c-dear-jackie-secondary};
}
.page-background--flute {
    --background: #{$c-flute};
    --foreground: #{$c-broadwick-pink-2};
    --swiper-pagination-color: #{$c-broadwick-pink-2};
}
.page-background--the-dining-room {
    --background: #{$c-the-dining-room};
}
.page-background--the-nook {
    --background: #{$c-the-nook};
    --foreground: #{$c-the-nook-secondary};
    --swiper-pagination-color: #{$c-the-nook-secondary};
}
.page-background--teal {
    --background: #{$c-broadwick-teal};
    --foreground: #{$c-broadwick-pink-2};
    --swiper-pagination-color: #{$c-broadwick-pink-2};
}
.page-background {
    background-color: var(--background);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div,
input,
label,
a,
dl,
cite {
    .page-background .colour-swap & {
        color: var(--foreground);
        border-color: var(--foreground);
    }
}

svg {
    .page-background .colour-swap & {
        fill: var(--foreground);
    }
}

.button--border-bottom::after {
    .page-background .colour-swap & {
        background-color: var(--foreground);
    }
}

.button,
button {
    .page-background .colour-swap & {
        border-color: var(--foreground);
        color: var(--foreground);

        &:hover {
            background-color: var(--foreground);
            color: var(--background);
        }
    }
}

.button--alt {
    .page-background .colour-swap & {
        border-color: var(--foreground);
        background: var(--foreground);
        color: var(--background);

        &:hover {
            background-color: var(--background);
            color: var(--foreground);
        }
    }
}
.header__booking {
    .page-background & {
        &:hover {
            background-color: var(--foreground);
            color: var(--background);
        }
    }
}

input:focus-visible {
    .page-background .colour-swap & {
        outline-color: var(--foreground);
    }
}

span::after,
span::before {
    .page-background .colour-swap & {
        background-color: var(--foreground);
    }
}

.footer__decoration,
.footer__decoration span {
    .page-background .colour-swap & {
        background-color: var(--foreground);
    }
}
.footer svg {
    .page-background & {
        fill: var(--foreground);
    }
}
