.dining {
    @include clearfix;

    .info-aside {
        margin-bottom: $u-spacer;
    }
}

    .dining__header {
        text-align: center;
        float: left;
        @include breakpoint(tablet) {
            float: none;
        }
    }
    .dining__aside {
        float: right;
        @include breakpoint(tablet) {
            float: none;
        }
    }

    .dining__title {
        @include large-title;
    }
    .dining__intro {
        @include intro;
        margin-bottom: $u-spacer;
    }
    .dining__body {
        margin-top: $u-spacer;
    }

.dining__menus {

}
    .dining__menus__file,
    .dining__menus__page {
        padding-left: 0;

        a {
            @include apply-map($f-info-desc);
            @include rem($f-info-desc-size);
            color: $c-info-desc;
            padding-left: 20px;
            &:hover {
                color: $c-brand;
            }
        }

    }
