/// Hide an element safely, i.e. the element is not visible to the user, but is visible to a screen reader
///
/// @author Joe Adcock <joe@d3r.com>

@mixin hideSafely {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}
