%svg-common {
  background: url("/images/sprite.svg") no-repeat;
}

@mixin svg-common {
  background: url("/images/sprite.svg") no-repeat;
}


@mixin ui-icon--breadcrumb {
  background-position: 98.03921568627452% 43.24324324324324%;
  width: 16px;
  height: 16px;
}

.icon--breadcrumb {
  @extend %svg-common;
  @include ui-icon--breadcrumb;
}


@mixin ui-icon--calendar {
  background-position: 0 52.94117647058823%;
  width: 17px;
  height: 19px;
}

.icon--calendar {
  @extend %svg-common;
  @include ui-icon--calendar;
}


@mixin ui-icon--check {
  background-position: 30.18867924528302% 90.2439024390244%;
  width: 14px;
  height: 12px;
}

.icon--check {
  @extend %svg-common;
  @include ui-icon--check;
}


@mixin ui-icon--download {
  background-position: 100% 0;
  width: 17px;
  height: 16px;
}

.icon--download {
  @extend %svg-common;
  @include ui-icon--download;
}


@mixin ui-icon--facebook {
  background-position: 70.6896551724138% 0;
  width: 9px;
  height: 18px;
}

.icon--facebook {
  @extend %svg-common;
  @include ui-icon--facebook;
}


@mixin ui-icon--google-plus {
  background-position: 44.680851063829785% 0;
  width: 20px;
  height: 18px;
}

.icon--google-plus {
  @extend %svg-common;
  @include ui-icon--google-plus;
}


@mixin ui-icon--pinterest {
  background-position: 33.333333333333336% 52.94117647058823%;
  width: 16px;
  height: 19px;
}

.icon--pinterest {
  @extend %svg-common;
  @include ui-icon--pinterest;
}


@mixin ui-icon--select-arrow {
  background-position: 0 100%;
  width: 16px;
  height: 16px;
}

.icon--select-arrow {
  @extend %svg-common;
  @include ui-icon--select-arrow;
}


@mixin ui-icon--twitter {
  background-position: 0 0;
  width: 21px;
  height: 18px;
}

.icon--twitter {
  @extend %svg-common;
  @include ui-icon--twitter;
}

