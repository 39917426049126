@import "../../../../cookie-centre/resources/sass/cookie-centre/cookie-settings";

$cookie-fontcolor: $c-copy;
// $cookie-altfontcolor: #fff;
$cookie-fontsize: $u-copy;
$cookie-bg: $c-band-bg;
// $cookie-radius: 3px;
$cookie-fg: $c-band-alt-bg;
$cookie-nav-bg: $c-brand;
$cookie-footer-bg: $c-aside;
$cookie-padding: 10px;
// $cookie-overlay: rgba(0,0,0,0.4);
// $cookie-switch-height: 30px;
// $cookie-switch-bg: #FFF;
$cookie-switch-fg: $c-brand;
$cookie-border: $c-border;

@import "../../../../cookie-centre/resources/sass/cookie-centre/cookie-audit";
@import "../../../../cookie-centre/resources/sass/cookie-centre/cookie-bar";
@import "../../../../cookie-centre/resources/sass/cookie-centre/cookie-preferences";

.cookie-preferences {
    &__title {
        @include large-title;
        margin-bottom: 0;
    }
    // &__nav-link {
    //     text-decoration: none;

    //     &:hover {
    //         color: #FFF;
    //     }
    //     &--active {
    //         color: $c-bodycopy;

    //         &:hover {
    //             color: $c-bodycopy;
    //         }
    //     }
    // }

    // &__statement {
    //     color: $c-brand;
    // }
}

.cookie-bar {
    border-top: 1px solid $c-border;

    &__wrap {
        // width: calc(90% + 20px);
        // max-width: 1200px;
        // margin: 0 auto;
        width: calc(#{$site-width} + #{2 * $cookie-padding});
        max-width: $site-max-width + 2 * $cookie-padding;
        // min-width: $site-min-width;
    }
    // &__copy {
    //     p {
    //         margin-bottom: 0;
    //     }
    // }
}
.cookie-bar__action--accept,
.cookie-preferences__submit,
.cookie-audit__prefs-button {
    @extend .button;
}
.cookie-audit {
    &__prefs {
        float: right;
        margin-bottom: 15px;

        @include breakpoint(mobile) {
            float: none;
            clear: both;
        }
    }
    .large-title {
        float: left;
    }
    &__title {
        clear: both;
        @include medium-title;
    }
    &__intro {
        @include intro;
        margin-bottom: 0.5em;
    }
    &__table {
        margin-bottom: 40px;
        width: 100%;
        @include copy;

        th, td {
            padding: 5px 10px;
            border: 1px solid $c-border;

            > :last-child {
                margin-bottom: 0;
            }
        }
        th {
            @include category;
        }
    }
        &__name {
            @include title;
        }
}

.cookie-preferences__panels {
    min-height: 250px;
}
