.map {
}

    .map__holder {
        height: 580px;

    }

    .map__infowindow {
        padding: 15px 0 10px 20px;

        img {
            max-width: 250px;
            margin-bottom: 20px;
        }
    }

    .map__infowindow--place {
        padding-left: 10px;
    }

    .map__infowindow--place {
        .listing__details {
            min-width: 220px;
        }
    }

    .map_infowindow__image {
        float: left;
        padding-right: 30px;

        @include breakpoint(mobile) {
            float: none;
            padding-right: 0;
        }
    }

    .map_infowindow__details {
        float: left;
        max-width: 400px;
         @include breakpoint(mobile) {
            float: none;
        }
        .listing__description {
            @include rem($u-copy);
        }
    }
