////
/// Default mixins for hospitality system
///
/// @author Dom Morgan <dom@d3r.com>
////

@import "../../../../core/resources/sass/mixins";

/// Backwards compatible opacity
/// @param {Float} $opacity
/// @author Dom Morgan <dom@d3r.com>
@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie);
}

/// Better antialiasing for light text on a dark background
/// @author Dom Morgan <dom@d3r.com>
@mixin lightondark {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

/// Dashed or dotted borders with bigger gaps/dots
/// Note that this uses background so borders will be int eh padding box,
/// not the border box.
/// @param {Color} $colour
/// @param {Number | List} $spacing between dots
/// @author Dom Morgan <dom@d3r.com>
@mixin dashed-border($colour, $spacing: 2px, $width: 1px, $padding: 0) {
    @if $padding != 0 {
        $paddingMap: explode-shorthand($padding);
        @each $side, $value in $paddingMap {
            padding-#{$side}: $value + 1px;
        }
    }
    border: none;
    $repeat: $spacing + $width;
    background-image: linear-gradient(
        to right,
        $colour 0px,
        $colour $width,
        transparent $width
    ),
    linear-gradient(
        to bottom,
        $colour 0px,
        $colour $width,
        transparent $width
    ),
    linear-gradient(
        to left,
        $colour 0px,
        $colour $width,
        transparent $width
    ),
    linear-gradient(
        to top,
        $colour 0px,
        $colour $width,
        transparent $width
    );
    background-size: $repeat 1px, 1px $repeat, $repeat 1px, 1px $repeat;
    background-repeat: repeat-x, repeat-y, repeat-x, repeat-y;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;

    .no-multiplebgs & {
        $style: dotted !default;
        @if ($width > 1px) {
            $style: dashed;
        }
        background: none;
        border: 1px $style $colour;
        @if $padding != 0 {
            padding: $padding;
        }
    }
}

/// Grid gutters
///
/// @param {Number} $gutter-x horizontal spacing
/// @param {Number} $gutter-y vertical spacing
/// @author Brendan Patterson <brendan@d3r.com>
@mixin grid-gutter($gutter-x:$grid-gutter-h, $gutter-y:$grid-gutter-v) {
    margin-right: -($gutter-x);
    > * {
        padding-right: $gutter-x;
        margin-bottom: $gutter-y;
    }
    // Use the correct negative margin for flush bottom
    &.grid--flush-bottom {
        margin-bottom: -($gutter-y);
    }
}

/// Remove white space from space charecters in html etc
/// @author Brendan Patterson <brendan@d3r.com>
@mixin remove-white-space {
    font-size: 0 !important;
    letter-spacing: normal;
    word-spacing: normal;
    & > * {
        display: inline-block;
        @include rem($u-copy);
    }
}

/// Remove margin-bottom from last child
/// @author Brendan Patterson <brendan@d3r.com>
@mixin flush-last-child {
    & > :last-child {
        margin-bottom: 0;
    }
}

/// Hide only visually, but have it available for screenreaders: h5bp.com/v
/// @author Brendan Patterson <brendan@d3r.com>
@mixin visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
}

/// Restore visually hidden to UA defaults
/// @author Brendan Patterson <brendan@d3r.com>
@mixin remove-visually-hidden {
    clip: auto;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    padding: 0;
    position: static;
}



$default-content-type: general;
/// A max width wrapper
///
/// @todo doesn't seem to change?
/// @param {String} $content-type
/// @author Brendan Patterson <brendan@d3r.com>
@mixin max-width-content($content-type: $default-content-type) {
    margin-left: auto;
    margin-right: auto;
    // By default, for intro's etc
    @if $content-type == general {
        max-width: $max-content-width;
    }
    // Large areas of bodycopy, eg richtext
    @else if $content-type == bodycopy {
        max-width: $max-content-width;
    }
    // Or a number
    @else if type-of($content-type) == number {
        max-width: $content-type;
    }
    @else {
        @warn "Unexpected value in max-width-content mixin";
    }
}


$default-bullet-behaviour: nowrap;

/// Faux list item
/// @param {String} $bullet-behaviour nowrap or wrap
/// @author Brendan Patterson <brendan@d3r.com>
@mixin list-item($bullet-behaviour: $default-bullet-behaviour) {

    $bullet-size: 0.333em;
    $bullet-margin: 0.8em;

    // Parent 'list' item
    position: relative;
    display: block;

    // Disc styling
    &::before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: $bullet-size;
        height: $bullet-size;
        background: $c-bullet;
        border-radius: 999px;
    }

    // Text cannot wrap under the disc
    @if $bullet-behaviour == nowrap {
        // Make space for the disc
        padding-left: $bullet-margin + $bullet-size;
        // Position the disc
        &::before {
            position: absolute;
            top: $f-lineheight * 0.5em;
            margin-top: -0.5 * $bullet-size;
            left: 0;
        }
    }

    // Text can wrap under the disc
    @if $bullet-behaviour == wrap {
        &::before {
            margin-right: $bullet-margin;
        }
    }
}

/// Absolute cover
/// @author Brendan Patterson <brendan@d3r.com>
@mixin absolute-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


$default-fallback-value: 0.5em;
/// Absolute cover
/// A bit magic numbery but kinda maybe should work, failing that pass in a var
/// @param {Number} $fallback-value
/// @author Brendan Patterson <brendan@d3r.com>
@mixin absolute-center($fallback-value: $default-fallback-value) {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    margin-top: -($default-fallback-value);
    #{$md-transforms} & {
        margin-top: auto;
        transform: translateY(-50%);
    }
}

/// Fill image
/// @author Brendan Patterson <brendan@d3r.com>
@mixin fill-image {
    width: 100%;
}

/// Photoshop tracking to letter spacing
/// @param {String | Number} $photoshop-value
/// @author Brendan Patterson <brendan@d3r.com>
@mixin tracking($photoshop-value) {

    // Check if value is a number
    @if type-of($photoshop-value) == "number" {
        // If its 0 change to 'normal' which is UA default
        @if $photoshop-value == "0" {
            letter-spacing: normal;
        }
        // Otherwise divide by 1000 which is approx calculation
        @else {
            letter-spacing: #{($photoshop-value / 1000)}em;
        }
    }

    // Allow for keywords
    @else if $photoshop-value == "normal", "default", "none" {
        letter-spacing: normal;
    }

    // Warn if anything else and apply UA default
    @else {
        @warn "expected numerical value in tracking mixin";
        letter-spacing: normal;
    }
}

/// Apply a map of keys to an element
///
/// @param {map} $props
/// @author Neil Brayfield <neil@d3r.com>
@mixin apply-map($props) {
    @each $name in map-keys($props) {
        #{$name}: map-get($props, $name)
    }
}
