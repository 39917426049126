.gallery-block {
    display: grid;
    gap: 10px;
    margin: 0 auto 10px;
    width: $site-width;
    max-width: $site-max-width;

    @include breakpoint(phablet) {
        max-width: none;
        padding: 0 to-rem($site-gutter--mobile);
        width: 100%;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &:last-child {
        margin-bottom: $u-band-padding-top;

        @include breakpoint(tablet) {
            margin-bottom: calc($u-band-padding-bottom / 2);
        }
    }
}


.gallery-block--split {
    grid-template-columns: 1fr 2fr;

    &.gallery-block--align-right {
        grid-template-columns: 2fr 1fr;

        @include breakpoint(phablet) {
            grid-template-columns: 1fr;
        }
    }
}

.gallery-block--with-button {
    margin-bottom: to-rem(80px);
}

.gallery-block--stacked-split {
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: column;

    &.gallery-block--align-left {
        figure:first-of-type {
            grid-row-end: span 2;
        }
    }

    &.gallery-block--align-right {
        figure:last-of-type {
            grid-row-end: span 2;
        }
    }
}

.gallery-block {
    @include breakpoint(phablet) {
        grid-auto-flow: unset;
        grid-template-columns: 1fr;
    }
}

.gallery-block__button {
    margin-top: to-rem(35px);
    text-align: center;
}
