.block {
    margin: to-rem(80px) auto;
    max-width: 100%;
    width: $site-width;
    max-width: $site-max-width;
    min-width: $site-min-width;

    @include breakpoint(phablet) {
        margin: to-rem(40px) auto;
        max-width: none;
        padding: 0 to-rem($site-gutter--mobile);
        width: 100%;
    }
}

.block--tight {
    padding-left: calc((1 / 12) * 100%);
    padding-right: calc((1 / 12) * 100%);

    @media screen and (min-width: 1600px) {
        padding: 0;
    }

    @include breakpoint(phablet) {
        padding: 0 to-rem($site-gutter--mobile);
    }
}
.block--fullwidth,
.block--fullscreen {
    width: 100%;
    margin-inline: 0;
    max-width: none;

    @include breakpoint(phablet) {
        padding: 0;
    }
}
.block--fullscreen {
    max-width: none;
    margin: 0;
}

.block__title {
    @include large-title;
}

.block__title--med {
    @include medium-title;
}

.block__intro {
    @include apply-map($f-title);
    @include font($u-intro, 28px);

    letter-spacing: 0.5px;
    text-transform: none;
}

@import 'blocks/banner';
@import 'blocks/call-to-action';
@import 'blocks/gallery';
@import 'blocks/illustration';
@import 'blocks/image-text';
@import 'blocks/inline-image';
@import 'blocks/inline-intro';
@import 'blocks/quote';
@import 'blocks/richtext';
@import 'blocks/vimeo-video';
