
.widget {
    .field {
        vertical-align: bottom;
        margin-bottom: 0;

        @include breakpoint(mobile) {
            margin-bottom: 20px;
        }
        label {
            color: $c-action-bar-copy;
        }
        .input,
        .select-replace__value {
            padding-top: $u-widget-input-vertical-padding;
            padding-bottom: $u-widget-input-vertical-padding;
            line-height: 17px;
        }
        select.input {
            height: calc(((17px + ($u-widget-input-vertical-padding * 2) + 5px) / $u-widget-input-vertical-padding) * 1em);
        }
    }
    .field--no-label {
        @include breakpoint(mobile) {
            padding-top: 0;
        }

    }

    .field--date {
        &:after {
            content: "";
            @extend .icon--calendar;
            position: absolute;
            right: 28px;
            bottom: 14px;
            pointer-events: none;
        }
    }
}

    .widget__small {
        min-width: 90px;
    }

    .widget__med {
        min-width: 120px;
    }

    .widget__large {
        min-width: 250px;

        @include breakpoint(tablet) {
            min-width: 0;
        }
    }

    .widget__tel {
        white-space: nowrap;
    }


    @include breakpoint(mobile) {
        .widget__no-mobile {
            display: none;
        }
    }

.widget__text.field {
    max-width: 130px;
    margin-bottom: 0;
    p {
        padding-top: 2px;
        @include smallcaps;
        @include rem(15px);
        text-align: center;
    }

    @include breakpoint(tablet) {
        margin-bottom: 0;
    }
    @include breakpoint(mobile) {
        max-width: 100%;
    }
}

.widget__button {
    min-width: 240px;
    button {
        line-height: 21px;
    }
}

@include breakpoint(desktop) {
    .widget__arrive {
        width: 28%;
    }

    .widget__table-date {
        width: 28%;
    }

    .widget__med {
        width: 14%;
    }
}

.widget--table {

    .widget__button {
        min-width: 180px;
    }
}

.widget__title {
    margin-bottom: 18px;
}
