body {
    --background: #{$c-broadwick-tan};
    --foreground: #{$c-brand};
    letter-spacing: 0.5px;
    width: 100%;
}

.outer-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $c-black;
        transition: opacity 400ms ease;
        opacity: 0;
        pointer-events: none;
        z-index: 60;

        @include breakpoint(phablet) {
            display: none;
        }

        body[data-scroll-lock] & {
            opacity: 0.5;
        }
    }
}

.inner-wrapper {
    min-height: calc(100vh - $u-header-height - $u-footer-height);

    .body--debug & {
        min-height: calc(100vh - $u-header-height - $u-footer-height - 20px);

        @include breakpoint(tablet) {
            min-height: calc(100vh - $u-header-height-mobile - $u-footer-height-mobile - 40px);
        }
    }

    .body--admin & {
        min-height: calc(100vh - $u-header-height - $u-footer-height - 64px);

        @include breakpoint(tablet) {
            min-height: calc(100vh - $u-header-height-mobile - $u-footer-height-mobile - 64px);
        }
    }

    .body--debug.body--admin & {
        min-height: calc(100vh - $u-header-height - $u-footer-height - 84px);

        @include breakpoint(tablet) {
            min-height: calc(100vh - $u-header-height-mobile - $u-footer-height-mobile - 84px);
        }
    }
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
