.room-index {
    display: grid;
    grid: auto / repeat(4, 1fr);
    column-gap: to-rem(10px);
    row-gap: to-rem(30px);
    > * {
        grid-column: span 2;
    }
    // centre an orphaned grid item
    > :last-child:nth-child(2n+1) {
        grid-column: 2 / -2;
    }
    @include breakpoint(tablet) {
        grid-template-columns: repeat(2, 1fr);
        max-width: to-rem($max-tab-width);
        margin: 0 auto;

        > :last-child:nth-child(2n+1) {
            grid-column: span 2;
        }
    }

}
