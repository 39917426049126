// Bands
.band {
    padding-top: $u-band-padding-top;
    padding-bottom: $u-band-padding-bottom;
    background: $c-band-bg;
    border-bottom: 1px solid $c-band-border;
    position: relative;
    & > :last-child:not(.grid) {
        margin-bottom: 0;
    }
    @include breakpoint(tablet) {
        padding-top: calc($u-band-padding-top /2);
        padding-bottom: calc($u-band-padding-bottom /2);
    }
}

.band--highlight {
    background: $c-band-highlight-bg;
    border-bottom: $c-band-highlight-border;
}

.band--alt,
.automated-bands .band:nth-child(even) {
    background: $c-band-alt-bg;
    border: $c-band-alt-border;
}

.band--compact {
    padding-top: calc($u-band-padding-top /2);
    padding-bottom: calc($u-band-padding-bottom /2);
}

.band--flush {
    padding-top: 0;
    padding-bottom: 0;
}

.band--flush-top {
    padding-top: 0;
}

.band--flush-bottom {
    padding-bottom: 0;
}

// Spacing for the one above some tabs
.band--tab-space {
    padding-bottom: $u-band-padding-bottom + $u-band-tab-height;
}
