div + .offer-details__title {
    margin-top: $u-med-spacer;
}

.offer-details__title {
    margin-bottom: $u-small-spacer;

    @include breakpoint(tablet) {
        text-align: left;
    }
}

.offer-details__terms-link {
    margin-bottom: $u-med-spacer;
}

.offer-details__list {
    li {
        &::before {
            content: '';
            position: absolute;
            left: 0;
            transform: rotate(45deg);
            background-color: $c-brand;
            height: 6px;
            width: 6px;
            border-radius: 0;
        }
    }
    @include breakpoint(tablet) {
        text-align: left;
    }
}
