#rd-widget-frame {
    max-width: 100%;
    margin: auto;
    padding: 20px 0;
}

#rd-widget-root {
    max-width: 100% !important;
}

#rd-widget-root .logo-panel {
    display: none;
}

#rd-widget-root .panel-body.booking-summary {
    text-align: end;
}

#rd-widget-root .custom-logo {
    display: none;
}

.rd-widget__powered {
    color: inherit !important;
    display: block;
    width: 130px;
    margin: 40px auto 0;
    text-align: center;
}

.rd-widget__powered__label {
    display: block;
    margin-bottom: 5px;
}

.rd-widget__powered__icon {
    display: inline-block;
    text-indent: -1000em;
    background: url(/images/ResDiary.png) no-repeat;
    background-size: cover;
    width: 130px;
    height: 27px;
}
