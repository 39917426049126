// Room nav

.room-nav {
    background: $c-room-nav-bg;
    padding: $u-room-nav-padding;
}

    .room-nav__wrap {
        @include clearfix;
        // Future projects may require max-width: 90% etc
    }

        .room-nav__option {
            @include apply-map($f-room-nav);
            @include rem($u-room-nav);
            color: $c-room-nav;
        }
        .room-nav__option--prev {
            float: left;

            @include breakpoint(tiny) {
                display: none;
            }
        }
        .room-nav__option--next {
            float: right;
        }

            .room-nav__price {
                @include smallcaps;
            }


