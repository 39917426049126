@import 'generic/pickadate/variables.scss';
@import 'generic/pickadate/base.scss';
@import 'generic/pickadate/base.date.scss';
@import 'generic/pickadate/classic.scss';
@import 'generic/pickadate/classic.date.scss';

.pickadate {
  position: relative;
  @include copy;
}
.picker button {
  color: $pickadate__black;
}

.picker__holder {
  outline: none;
}

.pickadate--min-size {
    .picker {
        min-width: 260px;
    }
}
