
.d3r-popups {
    height: 100%;
    position: relative;
}

    .d3r-popups__info {
        padding: 20px 20px;
        width:100%;
        text-align: center;
        align-items: center;

        @include breakpoint(mobile) {
            padding-left: 12px;
            padding-right: 12px;
        }
    }

    .d3r-popups__title {
        @include apply-map($f-title);
        font-size: 21px;
        letter-spacing: 1.7px;

        @include breakpoint(mobile) {
            font-size: 20px;
        }

        @include breakpoint(short) {
            font-size: 20px;
        }
    }

    .d3r-popups__image {
        width: 100%;
    }

    .d3r-popups__body {
        @include apply-map($f-title);
        font-size: 16px;
        letter-spacing: 1.7px;
        margin: 15px auto;

        @include breakpoint(phablet) {
            font-size: 14px;
        }

        @include breakpoint(short) {
            font-size: 14px;
        }
    }

    .d3r-popups__cta {
        @include apply-map($f-title);
        display: block;
        margin: 15px auto;
        width: 300px;
        max-width: 100%;

        @include breakpoint(phablet) {
            margin: 10px auto 10px auto;
        }

        @include breakpoint(short) {
            margin: 10px auto 10px auto;
        }

        @include breakpoint(mobile) {
        }
    }

    .d3r-popups__small-print {
        font-size: 12px;

        @include breakpoint(mobile) {
            font-size: 10px;
        }
        
        @include breakpoint(short) {
            font-size: 10px;
        }
    }
