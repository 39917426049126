@use 'sass:math';

.dining-intro {
    display: grid;
    grid: auto / 1fr 2fr;
    gap: to-rem($grid-gutter-h);

    @include breakpoint(tablet) {
        grid-template-columns: 1fr;
        gap: 0;
    }
}

    .dining-intro__details {
        padding-right: 25%;

        @include breakpoint(tablet) {
            padding-right: 0;
        }
    }

        .dining-intro__title {
            @include medium-title-non-responsive;
        }

        dl.dining-intro__opening-times {
            margin-bottom: to-rem(30px);

            @include breakpoint(tablet) {
                margin-bottom: to-rem(20px)
            }

            dt {
                @include apply-map($f-title);
                @include tiny-title;

                margin: 0;

                &:not(:first-of-type) {
                    margin-top: to-rem(15px);
                }
            }
            dl {
                @include font(12px, 24px);
            }
        }

    .dining-intro__list {
        margin-bottom: to-rem(40px);

        @include breakpoint(tablet) {
            display: flex;
            flex-wrap: wrap;
            gap: to-rem(17px);
            margin-bottom: to-rem(25px);
        }
    }

        .dining-intro__list-item {
            a {
                text-align: left;
            }
        }

    .dining-intro__content {

    }

        .dining-intro__intro {
            @include apply-map($f-title);
            @include font(16px, 30px);

            text-transform: none;
            letter-spacing: 0.5px;
            margin-bottom: to-rem(15px);

            @include breakpoint(tablet) {
                @include font(14px, 25px);

                margin-bottom: to-rem(25px);
            }
        }

        .dining-intro__body {
            margin-bottom: to-rem(30px);
        }

        .dining-intro__image {
            @include aspect(math.div(7, 4));

            img {
                width: 100%;
            }
        }

    .dining-intro__callout-grid {
        display: grid;
        grid: auto / 1fr 1fr;
        gap: to-rem($grid-gutter-h);

        @include breakpoint(phablet) {
            grid-template-columns: 1fr;
            gap: to-rem(10px);
        }
    }

        .dining-intro__callout-title {
            @include large-title;

            text-align: center;
        }

        .dining-intro__callout-item {
            display: block;
            position: relative;
        }

            .dining-intro__callout-image {
                @include aspect(math.div(7, 5));

                background-color: $c-black;

                img {
                    opacity: 0.8;
                    transition: transform 400ms ease-in-out;

                    .dining-intro__callout-item:hover & {
                        transform: scale(1.08);
                    }
                }
            }

            .dining-intro__callout-icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                svg {
                    width: 250px;
                    height: auto;
                    fill: $c-secondary;
                }
            }
