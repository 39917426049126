.room-details {
    @include clearfix;
}

    .room-details__header {
        text-align: center;
    }

    .room-details__title {
        @include large-title;
    }
    .room-details__intro {
        @include intro;
        margin-bottom: $u-spacer;
    }
    .room-details__body {
        margin-top: $u-spacer;
        text-align: left;
    }


    .room-details__detail {
        text-align: center;
    }
