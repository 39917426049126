.block--inline-intro {
    margin-bottom: to-rem(60px);
    display: grid;
    grid: auto / 1fr 4fr;
    gap: to-rem($grid-gutter-h);
    padding-left: calc((1 / 12) * 100%);
    padding-right: calc((1 / 12) * 100%);

    @include breakpoint(phablet) {
        grid-template-columns: 1fr;
        padding: 0 to-rem($site-gutter--mobile);
        gap: to-rem(10px);
        margin-bottom: to-rem(30px);
    }

    + .block {
        margin-top: to-rem(60px);

        @include breakpoint(phablet) {
            margin-top: to-rem(30px);
        }
    }

    &.block--gap {
        grid-template-columns: 2fr 3fr;

        @include breakpoint(phablet) {
            grid-template-columns: 1fr;
        }
    }

        .block__title {
            margin: 0;
        }

        .block__body {
            margin-top: to-rem(15px);
        }
}
