
.spinner {
    width: 30px;
    height: 30px;
    font-size: 0;
}

.spinner::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-radius: 50%;
    border-right-color: var(--foreground);
    opacity: 0.7;
    animation: spinner-anim 0.8s linear infinite;
}

@keyframes spinner-anim {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
