.wrap {
    margin: 0 auto;
    width: $site-width;
    max-width: $site-max-width;
    min-width: $site-min-width;

    @include breakpoint(phablet) {
        max-width: none;
        padding: 0 to-rem($site-gutter--mobile);
        width: 100%;
    }
}

.wrap--copy {
    max-width: $max-copy-width;
}

.wrap--narrow {
    padding-left: calc((1 / 12) * 100%);
    padding-right: calc((1 / 12) * 100%);

    @media screen and (min-width: 1600px) {
        padding: 0;
    }

    @include breakpoint(phablet) {
        padding: 0 to-rem($site-gutter--mobile);
        max-width: none;
        width: 100%;
    }
}

.wrap--tab {
    @include breakpoint(tablet) {
        max-width: $max-tab-width;
    }
}

.wrap--flush-mobile {
    @include breakpoint(phablet) {
        max-width: none;
        padding: 0;
    }
}

.wrap--center {
    text-align: center;
}
