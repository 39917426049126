.page-anchors {
    display: flex;
    justify-content: center;
    column-gap: to-rem(25px);
    margin: to-rem(40px) 0 to-rem(30px);

    @include breakpoint(phablet) {
        row-gap: to-rem(15px);
        max-width: to-rem(210px);
        display: flex;
        flex-wrap: wrap;
        justify-items: center;
        margin-left: auto;
        margin-right: auto;
    }
}

.page-anchors--large-spacing {
    margin-top: to-rem(65px);

    @include breakpoint(tablet) {
        margin-top: to-rem(40px);
    }
}

    a.page-anchors__link,
    button.page-anchors__link {
        @include tiny-title;
        
        font-weight: 700;
        margin-bottom: 0;
        padding: 0;
        border: none;
        color: var(--foreground);
        border-bottom: 1px solid transparent;
        transition: border 150ms ease;
        text-decoration: none;

        // solves specificity issue with .page-background
        .page-background .colour-swap & {
            border-color: transparent;
        }

        &:hover {
            background-color: transparent;
            color: var(--foreground);

            .page-background .colour-swap & {
                border-color: var(--foreground);
            }
        }
    }
