@keyframes underline {
    // 0% {
    //     width: 100%;
    // }
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

@mixin underline {
    position: relative;
    padding-bottom: 1em;

    &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        background: var(--underline-color, $c-brand);
        width: 100%;
    }

    &:hover {
        text-decoration: none;

        &::after {
            animation: underline 300ms $transition-function;
            width: 100%;
        }
    }
}
