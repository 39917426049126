.additional-rooms {
    @include breakpoint(tablet) {
        max-width: to-rem($max-tab-width);
        margin: 0 auto;
    }
}

    .additional-rooms__title {
        @include medium-title-non-responsive;

        text-align: center;
        color: $c-brand;
        margin-bottom: to-rem(25px);

        @include breakpoint(tablet) {
            text-align: left;
            margin-bottom: to-rem(15px);
        }
    }

    .additional-rooms__grid {
        display: grid;
        grid: auto / 1fr 1fr;
        gap: to-rem(10px);

        @include breakpoint(tablet) {
            grid-template-columns: 1fr;
            gap: to-rem(30px);
        }
    }
