.band-tabs {
    position: relative;
}

    .band-tabs__wrap {
        position: absolute;
        top: 0 - $u-band-padding-top - $u-band-tab-height;
        text-align: center;
        font-size: 0;
        width: 100%;
        .band--flush-top & {
            top: 0 - $u-band-tab-height;
        }
        .band--compact & {
            top: 0 - ($u-band-padding-top * .5) - $u-band-tab-height;
        }
    }

    .band-tabs__tab {
        display: inline-block;
        font-size: $base-px;
        height: $u-band-tab-height;
        line-height: $u-band-tab-height;
        background-color: $c-band-tab;
        min-width: $u-band-tab-min-width;
        text-align: center;
        border: 1px solid $c-band-border;
        border-radius: $u-band-tab-radius $u-band-tab-radius 0 0;
        color: $c-band-tab-text;
        margin-left: $u-band-tab-spacing;
        padding: 0 20px;

        @if $u-band-tab-spacing == 0 {
            border-left-width: 0;
        }

        &:first-child {
            margin-left: 0;
            @if $u-band-tab-spacing == 0 {
                border-left-width: 1px;
            }
        }
    }

    .band-tabs__tab--active {
        background-color: $c-band-tab-active;
        border-bottom-color: $c-band-tab-active;
        color: $c-band-tab-active-text;
    }
