.dialog {
    width: to-rem(800px);
    border: none;
    background-color: $c-secondary;
    padding: to-rem(70px) to-rem(60px) to-rem(60px);

    &::backdrop {
        background-color: $c-black;
        opacity: 0.5;
    }

    @include breakpoint(phablet) {
        overflow-y: scroll;
        width: 100%;
        height: 100%;
        padding: to-rem(70px) to-rem(45px) to-rem(50px);

        &:modal {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

.dialog--responsive {
    width: fit-content;
    padding: to-rem(70px) to-rem(80px);
    background-color: var(--background);
    min-width: to-rem(400px);

    @include breakpoint(phablet) {
        padding-left: to-rem(50px);
        padding-right: to-rem(50px);
        width: 100%;
        min-width: 100%;
    }
}

.dialog--adaptive-mobile {
    @include breakpoint(phablet) {
        height: fit-content;
    }
}

    .dialog__close {
        @include font(16px, 20px);

        position: absolute;
        top: 30px;
        right: 25px;
        letter-spacing: 2px;
        border-color: inherit;
        padding: 0;
        border: none;

        &:hover {
            color: $c-button;
            background-color: transparent;
        }

        .dialog--responsive & {
            color: var(--foreground);
        }
    }

    .dialog__title {
        @include medium-title;

        text-align: center;
        color: $c-brand;
        margin-bottom: to-rem(50px);

        @include breakpoint(tablet) {
            margin-bottom: to-rem(20px);
        }

        .dialog--responsive & {
            color: var(--foreground);
            margin-bottom: to-rem(20px);

            @include breakpoint(phablet) {
                text-align: left;
            }
        }
    }

    .dialog__link {
        display: block;
        text-align: center;

        @include breakpoint(phablet) {
            text-align: left;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .dialog__link--no-link {
        &:hover {
            text-decoration: none;
        }
    }

    .dialog__image {
        width: 100%;
        height: auto;
    }

    .dialog__list {
        column-count: 2;
        break-inside: avoid-column;
        column-gap: to-rem($grid-gutter-h);

        @include breakpoint(phablet) {
            column-count: 1;
        }
    }

    .dialog__menu-list {
        display: flex;
        flex-direction: column;
        gap: to-rem(20px);
        align-items: center;

        @include breakpoint(phablet) {
            align-items: flex-start;
        }
    }

        .dialog__list-item {
            display: flex;
            gap: to-rem(15px);
            color: $c-brand;

            svg {
                fill: $c-brand;
                height: 17px;
                margin-top: to-rem(5px);
                flex-shrink: 0;
            }
        }

    dl.dialog__opening-hours {
        display: grid;
        justify-items: center;
        grid-template-columns: 1fr 1fr;
        gap: to-rem(10px);

        @include breakpoint(phablet) {
            grid-template-columns: 1fr;
            justify-items: flex-start
        }

        dt,
        dd {
            @include apply-map($f-title);
            @include font(16px, 20px);

            letter-spacing: 0.5px;
            text-transform: capitalize;
        }

        dt {
            @include breakpoint(phablet) {
                &:last-of-type {
                    margin-top: to-rem(15px);
                }
            }
        }
    }
