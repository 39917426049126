.block--quote,
.quote {
    max-width: calc((2 / 3) * 100%);
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    @include breakpoint(phablet) {
        max-width: 100%;       
    }

    .block__quote {
        font-style: normal;
        line-height: 38px;
        text-transform: none;

        @include breakpoint(phablet) {
            @include font(20px, 34px);
        }
    }

    .block__source {
        margin-top: to-rem(20px);
        color: $c-brand;
        font-weight: 700;
    }
}
