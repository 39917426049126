@keyframes pulse {
	0% {
		opacity: 1;
	}

	40% {
		opacity: .2;
	}

	100% {
		opacity: 1;
	}
}

.block--vimeo-video {
    text-align: center;

    .block__title {
        margin-bottom: 0;
    }

    .block__copy {
        margin-bottom: to-rem(35px);

        @include breakpoint(phablet) {
            display: flex;
            flex-direction: column;
            margin: 0 to-rem($site-gutter--mobile) to-rem(15px);
        }
    }
    .block__video {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            border: 1px solid var(--foreground);
            z-index: 0;
        }

        iframe {
            position: relative;
            display: block;
            z-index: 1;
        }

        .spinner {
            position: absolute;
            left: 50%;
            top: calc(50% - 40px);
            transform: translate(-50%, -50%);
        }

        svg {
            width: 200px;
            position: absolute;
            z-index: 0;
            left: 50%;
            top: calc(50% + 20px);
            transform: translate(-50%, -50%);
            fill: var(--foreground) !important;
            animation: pulse 2.4s infinite;
        }
    }
    &.block--fullscreen .block__video {
        overflow: hidden;
        @include full-height;

        video {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        iframe {
            position: relative;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: auto;
            height: auto;
            min-height: 100%;
            min-width: 100%;
        }
    }

    .block__video + .button {
        margin-top: 2.5rem;
    }

    &:last-child {
        margin-bottom: 2.5rem;
    }
}
