.signup-form .field--email {
    position: relative;
    margin-bottom: to-rem(20px);

    label.field__label {
        @include hideSafely;
    }

    input.input {
        @include font(16px, 20px);

        background-color: transparent;
        border: none;
        border-bottom: 1px solid $c-brand;
        border-color: var(--foreground);
        width: 100%;
        padding-left: 0;
        padding-bottom: to-rem(15px);
        padding-top: to-rem(10px);
        text-align: left;

        &:focus-visible {
            outline: none;
        }

        &::placeholder {
            @include font(16px, 20px);
            opacity: 0.75;
            color: var(--foreground);
        }
    }
}

.field--date .input {
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: right 10px center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='20' viewBox='0 0 20 20' fill='%239d2235' width='20'%3E%3Crect fill='none' height='20' width='20' x='0'/%3E%3Cpath d='M15.5,4H14V2h-1.5v2h-5V2H6v2H4.5C3.67,4,3,4.68,3,5.5v11C3,17.32,3.67,18,4.5,18h11c0.83,0,1.5-0.68,1.5-1.5v-11 C17,4.68,16.33,4,15.5,4z M15.5,16.5h-11V9h11V16.5z M15.5,7.5h-11v-2h11V7.5z M7.5,12H6v-1.5h1.5V12z M10.75,12h-1.5v-1.5h1.5V12z M14,12h-1.5v-1.5H14V12z M7.5,15H6v-1.5h1.5V15z M10.75,15h-1.5v-1.5h1.5V15z M14,15h-1.5v-1.5H14V15z'/%3E%3C/svg%3E");
}

select.input {
    padding: 0 10px;
    min-height: 35px;
    text-align: left;
    appearance: none;
    line-height: normal;
    background-position: right 15px center;
    background-size: 14px 12px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%239d2235' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}
