@use 'sass:math';

$z-index-cookies: 2147483645;

.home-hero {
    @include full-height();
    width: 100%;
    position: relative;
    z-index: 11;
    overflow: hidden;

    .automated-bands,
    .home-hero__image {
        visibility: hidden;
    }
}

.home-hero--start-animation {
    border: 15px solid $c-brand;

    @include breakpoint(tablet) {
        border: 10px solid $c-brand;
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border: 10px solid $c-secondary;
        transition: border-width 300ms ease-in;
        z-index: 3;

        @include breakpoint(phablet) {
            border-width: 5px;
        }
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: $c-background;
        z-index: 12;
    }
}

.home-hero--end-animation {
    height: auto !important;
    z-index: 1;
    border: none;

    &::after {
        opacity: 0;
        z-index: 1;
    }

    &::before {
        border-width: 0;
    }

    .automated-bands,
    .home-hero__image {
        visibility: visible;
    }
}

    .home-hero__image {
        @include full-height();
        position: relative;
        opacity: 0;
        animation: 300ms fadeIn ease-in forwards;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: to-rem(330px);
            width: 100%;
            opacity: 0.4;
            background: linear-gradient(180deg, #040303 0%, rgba(0,0,0,0) 100%, rgba(0,0,0,0) 100%);
            z-index: 2;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .home-hero__canvas-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, calc(-50% - 85px));
        margin: auto;
        z-index: 20;
        display: flex;
        justify-content: center;
        opacity: 0;
        transition: opacity 300ms ease-in;

        @include breakpoint(phablet) {
            transform: translate(-50%, calc(-50% - 70px));
        }

        canvas {
            max-width: to-rem(1000px);

            @include breakpoint(phablet) {
                max-width: to-rem(800px);
            }
        }
    }

    .home-hero__canvas-container--visible {
        opacity: 1;
    }

    .home-hero__canvas-container--hidden {
        display: none;
    }

    .home-hero__video {
        position: absolute;
        z-index: 10000;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .home-hero__logo {
        position: absolute;
        bottom: 40%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 15;
        opacity: 0;
        transition: opacity 300ms ease-in;

        @include breakpoint(phablet) {
            width: 80%;
        }

        svg {
            fill: $c-brand;
            width: 100%;
        }
    }

    .home-hero__logo--visible {
        opacity: 1;
    }

    .home-hero__logo--hidden {
        display: none;
    }

    .home-hero__caption {
        @include apply-map($f-title);
        @include font(20px, 28px);

        position: absolute;
        text-align: center;
        margin-top: 20px;
        bottom: -60px;
        left: 0;
        width: 100%;

        @include breakpoint(phablet) {
            @include font(16px, 24px);
            bottom: -40px;
        }
    }
