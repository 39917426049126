.two-col-sections {
    column-count: 2;
    width: 93%;
    column-gap: 11%;

    @include breakpoint(laptop) {
        column-gap: 40px;
        width: auto;
    }

    @include breakpoint(tablet) {
        column-count: 1;
    }
}

.two-col-sections__section {
    break-inside: avoid;
    margin-bottom: 50px;
}

.two-col-sections__section-title {
    @include medium-title
}

.two-col-sections__section-body {
}
