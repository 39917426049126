.band-tabs {
    margin-bottom: $grid-gutter-v;
}

.band-tabs__wrap {
    position: static;
}

.band-tabs__tab {
    @include smallcaps;

    padding: 0;
    border: none;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        background-color: $c-brand;
        width: 0;
    }

    &:hover::after {
        animation: growBorder 400ms ease forwards;
    }
}

.band-tabs__tab--active {
    border-bottom: 1px solid $c-border;
    padding-bottom: 8px;
    font-weight: 700;

    &:hover::after {
        animation: none;
    }
}
