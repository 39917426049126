img,
video {
    max-width: 100%;
    height: auto;
    display: block;

    .grid__item > & {
        width: 100%;
    }

    #ie8 & {
        width: inherit;
    }

	&.fill {
		width: 100%;
	}

}
