.rule {
    display: block;
    position: relative;
    height: 1px;
    background-color: $c-brand;
    margin: 50px auto;

    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 0;
        transform: translateY(-50%);
        background-color: $c-brand;
        height: 6px;
        width: 6px;
        border-radius: 50%;
    }

    &::before {
        left: 0;
    }

    &::after {
        right: 0;
    }

    span {
        width: 40px;
        height: 1px;
        position: absolute;
        background: $c-body-bg;
        left: 50%;
        transform: translateX(-50%);

        &::before {
            content: '';
            position: absolute;
            top: -3px;
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
            background-color: $c-brand;
            height: 6px;
            width: 6px;
        }
    }
}
