.centred-form {
    text-align: center;
    margin: 0 auto;
    max-width: 330px;

    @include breakpoint(phablet) {
        max-width: none;
    }

    .field__label {
        text-align: center;
        @include smallcaps();
    }
    .input {
        border-width: 0 0 1px;
        text-align: center;
    }
    input.input:focus {
        box-shadow: none;
    }
}

    .centred-form__title {
        @include apply-map($f-title);
        @include font(22px, 32px);

        margin: 0 auto to-rem(20px);
        letter-spacing: 1px;
        max-width: to-rem(450px);
        text-align: center;

        @include breakpoint(phablet) {
            max-width: none;
        }
    }

    .centred-form__title--large {
        @include font(35px, 47px);

        letter-spacing: 2.4;
        max-width: none;
    }

    .centred-form__intro {
        @include apply-map($f-title);
        @include font(16px, 28px);

        margin: 0 auto to-rem(30px);
        max-width: to-rem($section-intro-max-width);
        text-transform: none;
        text-align: center;
    }

    .centred-form__field {
        position: relative;
        margin-bottom: to-rem(20px);

        label {
            @include apply-map($f-title);
            @include font(12px, 16px);

            letter-spacing: 1.4px;
            position: absolute;
            top: 5px;
            left: 50%;
            transform: translateX(-50%);
        }

        input {
            background-color: transparent;
            border: none;
            border-bottom: 1px solid $c-brand;
            width: 100%;
            padding-bottom: to-rem(5px);
            padding-top: to-rem(20px);

            &:focus-visible {
                outline: 2px solid $c-brand;
                outline-offset: -1px;
            }
        }
    }

    .centred-form__note {
        margin-top: to-rem(25px);

        p {
            @include font(12px, 22px);

            letter-spacing: 0.25px;
            margin-bottom: to-rem(20px);
        }
    }
