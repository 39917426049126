.callout {
    @include flush-last-child;
    padding: 20px;
    overflow: hidden;
    border-radius: 2px;
    border: 1px solid $c-callout-border;
    background: $c-callout-bg;
    text-align: center;
}

#{$md-flexbox} {
    .callout {
        display: flex;
        flex-direction: column;
    }
}

.callout__header {
    display: block;
    position: relative;
    margin-bottom: 20px;
    @include aspect($u-callout-aspect);

    &:after {
        @include absolute-cover;
        background: rgba(black, 0.3);
        visibility: visible;
    }

    img {
        width: 100%;
    }
}

.callout__header-content {
    @include absolute-center;
    @include flush-last-child;
    color: #fff;
    z-index: 2;
    padding: 0 20px;
}

.callout__title {
    @include apply-map($f-callout-title);
    @include rem($u-callout-title);
    color: $c-callout-title;
    margin-bottom: 10px;
}

.callout__description {
    margin-bottom: 15px;
}

#{$md-flexbox} {
    // Align actions at the bottom if possible
    .callout__action {
        margin-top: auto;
    }
}
