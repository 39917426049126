$cookie-fontcolor: $c-brand;
// $cookie-altfontcolor: $c-secondary;
// $cookie-fontsize: 13px;
$cookie-copy-fontsize: 14px;
$cookie-lineheight: 1.7;
$cookie-fontweight: 300;
// $cookie-bg: #ECEAE6;
$cookie-radius: 0px;
// $cookie-fg: $c-white;
// $cookie-secondary: $c-button;
// $cookie-footer-bg: $c-background;
// $cookie-padding: 10px;
// $cookie-overlay: rgba(0,0,0,0.4);
// $cookie-switch-height: 30px;
$cookie-switch-bg: $c-background;
$cookie-switch-fg: $c-brand;
// $cookie-border: $c-border;
// $cookie-btn-texttransform: uppercase;
$cookie-btn-letterspacing: 1.38px;
$cookie-btn-fontweight: 700;
$cookie-btn-fontsize: .75rem;
$cookie-btn: $c-button;
$cookie-btn-bg: $c-button-bg;
$cookie-btn-onhover: $c-button-hover;
$cookie-btn-onhover-bg: $c-button-bg-hover;
$cookie-wrappadding: 10px 25px;
$cookie-wrappadding-mobile: 10px 13px;

@import "cookie-centre/cookie-settings";
@import "cookie-centre/cookie-onetrust";

#onetrust-consent-sdk {
    font-family: $f-alt-stack;
}

//buttons
#onetrust-accept-btn-handler,
#onetrust-reject-all-handler,
#accept-recommended-btn-handler,
.save-preference-btn-handler,
.ot-pc-refuse-all-handler,
#ot-sdk-btn {
    font-family: $f-alt-stack !important;
    -webkit-font-smoothing: antialiased !important;
    border-style: solid !important;
    border-width: $u-button-border !important;
    border-color: $c-button-border !important;
    padding: $u-button-padding !important;
}

#onetrust-accept-btn-handler {
    background: $c-button-alt-bg !important;
    color: $c-button-alt !important;

    &:hover {
        background-color: $c-button-alt-bg-hover !important;
        color: $c-button-alt-hover !important;
    }
}
#onetrust-reject-all-handler {
    border-color: transparent !important;

    &:hover {
        border-color: $c-brand !important;
        background-color: transparent !important;
        color: $c-button-alt-hover !important;
    }
}

.otFlat {
    #onetrust-button-group-parent {
        // margin: 20px 0 0 !important;
        padding: 0 !important;

        .banner-actions-container {
            margin: 0 !important;
        }
    }

    #onetrust-button-group #onetrust-accept-btn-handler,
    #onetrust-button-group #onetrust-reject-all-handler,
    > * {
        margin: 5px !important;
    }
    .ot-sdk-row {
        max-width: none !important;
    }
}

// borders
#onetrust-pc-sdk .ot-accordion-layout.ot-cat-item,
#onetrust-pc-sdk .ot-pc-header,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table th,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table td,
 {
    border-color: $c-brand !important;
}
#onetrust-pc-sdk .ot-pc-footer-logo {
    background: $c-secondary !important;
}
#onetrust-pc-sdk .ot-tgl .ot-switch .ot-switch-nob {
    border: 1px solid $cookie-switch-fg !important;
    background-color: $cookie-switch-bg !important;

    &:before {
        border-color: $cookie-switch-fg !important;
        background-color: $cookie-switch-fg !important;
    }
}

#onetrust-pc-sdk .ot-cat-grp .ot-always-active {
    font-weight: $cookie-fontweight !important;
    color: $c-brand !important;
}

.ot-sdk-cookie-policy-title,
.ot-sdk-cookie-policy-group {
    font-family: $f-alt-stack !important;
}

#onetrust-pc-sdk .ot-pc-scrollbar::-webkit-scrollbar-thumb {
    background: initial !important;
}
