@use 'sass:math';

.overlay {
    .overlay__container.overlay__container {
        max-width: to-rem(430px);

        @include breakpoint(phablet) {
            max-width: 100%;
        }
    }

    .overlay__content {
        background-color: $c-body-bg;

        @include toolbars(padding);
    }
    .overlay__close {
        display: none;
    }
}

.overlay.overlay--sidebar {
    .overlay__bg {
        background-color: rgba($c-black, .5);
    }
}

body[data-header-position="sticky"] {
    .overlay {
        .overlay__content.overlay__content {
            padding-top: 0;
        }
    }
}

.overlay--popup.overlay--popup {
    .overlay__container.overlay__container {
        overflow: visible;
        max-width: 700px;
        width: 95%;
        padding-top: 0;

        @include breakpoint(short) {
            max-width: 550px;
        }
    }

    .overlay__content.overlay__content {
        overflow: auto;
        border: 5px solid $c-broadwick-red;
        height: 100%;
        padding-top: 0;
    }

    .overlay__bg.overlay__bg {
        background-color: $c-broadwick-red;
        opacity: 0.75;
    }

    .overlay__close.overlay__close {
        display: block;
        top: -35px;
        right: 5px;
        max-width: 18px;
        padding: 0;
        color: $c-lod-title;
        border: none;

        &:hover {
            background: none;
            border: none;
        }

        &::before {
            color: $c-lod-title;
            position: absolute;
            top: 3px;
            left: -80px;
            content: "Close";
            font-size: 16px;
            text-transform: uppercase;
        }
    }
}
