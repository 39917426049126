.hero-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    height: to-rem(100px);

    svg {
        max-width: to-rem(200px);
    }
}

.hero-logo--flute {
    svg {
        max-height: to-rem(60px);
    }
}

.hero-logo--the-nook {
    svg {
        max-height: to-rem(82px);
    }
}
