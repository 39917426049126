.action-bar {
    @include apply-map($f-action-bar-copy);
    @include rem($f-action-bar-copy-size);
    background: $c-action-bar-bg;
    padding: $u-action-bar-padding-top 0 $u-action-bar-padding-bottom 0;
    text-align: center;
    color: $c-action-bar-copy;
    position: relative;
}

.action-bar--secondary {
    border-style: solid;
    border-color: $c-action-bar-secondary-border;
    border-width: 1px 0;
    background: $c-action-bar-secondary-bg;
    color: $c-action-bar-secondary-copy;
}

.action-bar__string {
    margin-left: 0.5em;
}
