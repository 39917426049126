.footer {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: to-rem(75px) to-rem($site-gutter) to-rem(15px);
    margin-top: to-rem(-40px);

    @include breakpoint(tablet) {
        flex-direction: column;
        align-items: center;
        padding: to-rem(55px) to-rem($site-gutter--mobile) to-rem(30px);
        margin-top: to-rem(-20px);
    }

    @include breakpoint(phablet) {
        padding-bottom: to-rem(80px);
    }

    .homepage & {
        margin-top: 0;
    }
}

    .footer__decoration {
        display: block;
        position: absolute;
        top: 50px;
        left: 10px;
        right: 10px;
        height: 1px;
        background-color: $c-brand;

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0;
            transform: translateY(-50%);
            background-color: $c-brand;
            height: 6px;
            width: 6px;
            border-radius: 50%;
        }

        &::before {
            left: 0;
        }

        &::after {
            right: 0;
        }

        span.footer__diamond {
            width: 40px;
            height: 1px;
            position: absolute;
            background-color: var(--background);
            left: 50%;
            transform: translateX(-50%);

            .page-background .colour-swap & {
                background-color: var(--background);
            }

            &::before {
                content: '';
                position: absolute;
                top: -3px;
                left: 50%;
                transform: translateX(-50%) rotate(45deg);
                background-color: $c-brand;
                height: 6px;
                width: 6px;
            }
        }
    }

    .footer__contact {
        @include apply-map($f-title);
        @include font(14px, 25px);

        letter-spacing: 1px;
        text-transform: none;
        max-width: 40%;

        @include breakpoint(tablet) {
            text-align: center;
            margin-bottom: to-rem(10px);
            max-width: 80%;
        }
    }

        .footer__address {
            margin-bottom: to-rem(15px);

            @include breakpoint(tablet) {
                display: flex;
                justify-content: center;
                gap: to-rem(5px);
                margin-bottom: 0;
            }
            
            span {
                display: block;

                @include breakpoint(tablet) {
                    display: inline-block;
                }
            }
        }

        .footer__phone,
        .footer__email {
            display: block;

            @include breakpoint(tablet) {
                display: flex;
                justify-content: center;
            }
        }

        .footer__email--press {
            margin-top: to-rem(15px);
            text-wrap: wrap;

            @include breakpoint(tablet) {
                padding-top: to-rem(10px);
                margin-top: 0;
            }
        }

    .footer__logo {
        position: absolute;
        top: to-rem(85px);
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;

        @include breakpoint(tablet) {
            position: relative;
            top: 0;
            left: 0;
            transform: none;
            order: -1;
            margin: to-rem(20px) 0;
        }

        svg {
            max-width: to-rem(220px);
            height: auto;
            fill: $c-brand;
        }
    }

    .footer__navigation {
        display: grid;
        grid: auto / 1fr 1fr;
        flex-grow: 0;
        column-gap: to-rem(28px);

        @include breakpoint(tablet) {
            justify-items: center;
        }
    }

        .footer__navigation-item {
            @include apply-map($f-primary);
            @include font(12px, 26px);

            letter-spacing: 1px;
            display: block;
            width: fit-content;
            white-space: nowrap;
        }

        .footer__socials {
            list-style: none;
            display: flex;
            gap: to-rem(10px);
            grid-column: 2/3;
            margin-top: to-rem(7px);

            @include breakpoint(tablet) {
                grid-column: 2/3; // change this to 1/3 when we have gifts re-added
                gap: to-rem(15px);
            }

            svg {
                fill: $c-brand;
            }
        }
    
    .footer__credit-wrap {
        @include font(11px, 22px);

        margin-top: to-rem(15px);
        letter-spacing: 1px;
        flex-basis: 100%;
        display: flex;
        justify-content: flex-end;

        @include breakpoint(tablet) {
            justify-content: center;
            margin-top: to-rem(10px);
        }

        span,
        a {
            color: $c-broadwick-sand !important; // :( but we want to override the colour swap
        }
    }

        #credit-link {
            margin-left: to-rem(5px);

            a:hover {
                text-decoration: underline;
            }
        }
