.treatment-category {

}
.treatment-category--reverse {
    > .grid {
        direction: rtl;

        > * {
            direction: ltr;
        }
    }
}
    .treatment-category__content {
        text-align: left;
    }
    .treatment-category__title {
        @include large-title;
    }
    .treatment-category__intro {
        // @include copy;
        margin-bottom: $u-med-spacer;
    }
    .treatment-category__cta {
    }

.treatment {
    padding: 15px;
    &:hover {
        background-color: #CCC;
    }
}
    .treatment__title {
        @include small-title;
    }
    .treatment__pricing {
        margin: 5px 0;
    }
    .treatment__price {
        span {
            @include smallcaps;
            &:before {
                content: " - ";
            }
            &:first-child {
                &:before {
                    content: "";
                }
            }
        }
    }
    .treatment__enquire {
        @include smallcaps;
    }
