// details aside

.info-aside {
    padding-left: 30px;
    margin-left: 30px;
    border-left: 1px solid $c-info-border-color;
}

@include breakpoint(tablet) {
    .info-aside {
        margin-left: 0;
        padding-left: 0;
        border: none;
    }
}

.info-aside__group {
    margin-bottom: $u-med-spacer;
}

.info-aside__group--border-top {
    padding-top: $u-med-spacer;
    border-top: 1px solid $c-info-border-color;
    padding-bottom: $u-med-spacer;
}

.info-aside__figure {

}
    .info-aside__figcaption {
        @include smallprint;
    }

//

.info__title {
    @include apply-map($f-info-title);
    @include rem($f-info-title-size);
    margin-top: $u-med-spacer;
    color: $c-info-title;

    &:first-of-type {
        margin-top: 0;
    }
}

.info__desc {
    @include apply-map($f-info-desc);
    @include rem($f-info-desc-size);
    color: $c-info-desc;
}

    .info__link-list {
        &:before {
            content: " | ";
        }
        &:first-child:before {
            content: "";
        }
    }

.info__elaboration + .info__desc {
    margin-top: $u-small-spacer * .5;
}

.info__smallprint {
    display: block;
    margin-top: 5px;
    @include smallprint;
}

.info__address {
    @include apply-map($f-info-desc);
    @include rem($f-info-desc-size);
    color: $c-info-desc;
    margin-bottom: 20px;
    line-height: 1.4;
}

.info__jump-nav {
    @include smallcaps;
    margin-top: 15px;
    display: inline-block;
}
