.input {
    @include rem($f-field-size);
    @include apply-map($f-field);
    margin: 0;
    color: $c-field;
    outline: none;
}

input.input,
textarea.input {
    display: block;
    padding: $u-field-padding;
    border: 1px solid $c-field-border;
    background: $c-field-bg;
    border-radius: $u-field-radius;
    color: $c-field;
    transition: box-shadow .3s;
    -webkit-appearance: none;
    &:focus {
        box-shadow: $c-field-outline;
    }
}
input.input {
    line-height: $u-field-lineheight;
}
textarea.input {
    resize: none;
    line-height: 1.5;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
select.input {
    @include apply-map($f-field);
    @include rem($f-field-size);
    height: calc(
        (
            $u-field-lineheight * $f-field-size
            + extract-shorthand($u-field-padding, top)
            + extract-shorthand($u-field-padding, bottom)
            + 2px
        )
        * 1em / $f-field-size
        );
    padding: 0;
    margin: 0;
}
#ie6 select.input,
#ie7 select.input,
#ie8 select.input {
    padding: 1px 3px 1px;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
    select.input {
        border-radius: 0;
        -webkit-appearance: menulist-button;
        border-color: $c-field-border;
        padding: 0px;
        background: $c-field-bg;
    }
}

.field {
    text-align: left;
    position: relative;
    // margin-right: 22px;
    margin-bottom: $u-field-margin-bottom;

    label {
        @include apply-map($f-field-label);
        @include rem($f-field-label-size);
        color: $c-field-label;
        margin: 0 0 5px;
        display: block;

        span {
            color: $c-negative;
        }
    }
    small {
    }
    input.input,
    textarea.input {
        box-sizing: border-box;
        width: 100%;
    }
    textarea.input {
/*      max-width: 100%;*/ /*doesnt work in ff*/
    }
    input.cleverlabel,
    textarea.cleverlabel {
        color: #999;
    }
    select.input {
        width: 100%;
    }
    ::-webkit-input-placeholder {
        color: #999;
    }
    input.cleverlabel,
    textarea.cleverlabel {

    }
    p.error {
        padding-top: 3px;
        text-align: left;
        color: $c-negative;
        margin: 0;
    }
    .js & .form_note {
        display: none;
    }
}
.field.check_box {

    label {
        display: block;
    }
    input {
        margin-right: 5px;
    }
}
.field-select {

    label {
        // display: inline-block;
    }
}

.date-select {
    text-align: left;

    .select-replace,
    > select.input {
        margin-right: 5px;
        display: inline-block;
        width: auto;
    }
    p.error {
        clear: both;
        margin-right: 0;
    }
}
.field--time-select {
    font-size: 0;

    label {
        @include rem($f-field-size);
    }

    select.input {
        display: inline-block;
        width: 48.5%;
    }
    select.input:nth-of-type(1) {
        margin-right: 3%;
    }
}

div.password {
    .cleverpassword {
        position: relative;
    }
    input {
        position: relative;
    }
    input.cleverlabel {
        position: absolute;
        z-index: 0;
        left: 0;
        bottom: 0;
    }
}

.field-error textarea.input,
.field-error input.input {
    background-color: $c-field-error-bg;
}

.js  {
    .antispam-hide {
        display: none;
    }
}
.antispam {
    &.field {
        margin-bottom: 0;
    }
    p {
        margin: 0 10px 0 0;
    }
}

.select-replace {
    display: block;
    position: relative;

    select {
        @include opacity(0);
        position: relative;
        width: 100%;
        z-index: 2;
        -webkit-appearance: menulist-button;
        -moz-appearance: none;
    }
    &--focused {
        outline: none;
        box-shadow: $c-field-outline;
    }

    &__value {
        @include apply-map($f-field);
        @include rem($f-field-size);
        // line-height: (($u-field-lineheight * $f-field-size + extract-shorthand($u-field-padding, top) + extract-shorthand($u-field-padding, bottom) + 2px) / $f-field-size) * 1em;
        border: 1px solid $c-field-border;
        background: $c-field-bg;
        padding: extract-shorthand($u-field-padding, top)
                (extract-shorthand($u-field-padding, right) + 15px)
                extract-shorthand($u-field-padding, bottom)
                (extract-shorthand($u-field-padding, left) - 2px)
                ;
        text-align: left;
        line-height: 1;
        color: $c-field;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        vertical-align: text-top;
        display: block;
        margin-bottom: 0;
        box-sizing: border-box;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        word-break: break-all;
        border-radius: $u-field-radius;

        &:after {
            @extend .icon--select-arrow;
            display: block;
            content: "";
            position: absolute;
            right: 8px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.field__note {
    display: table;
    min-height: $u-field-note-min-height;
    p {
        display: table-cell;
        vertical-align: middle;
        padding-right: 10px;
    }
}

.field__right-button {
    float: right;
    @include breakpoint(tablet) {
        float: none;
        margin-bottom: 10px;
    }
}
