.hero {
    display: block;
    position: relative;
    overflow: hidden;

    &:before {
        float: left;
        content: "";
        padding-bottom: calc(100% / $u-hero-aspect);
        margin-bottom: -0.5px; // never go over
    }


    img {
        margin: 0 auto;
        width: 100%;
        max-width: none;
    }

    @include breakpoint(mobile) {
        @include aspect($u-hero-aspect-tablet);
    }
}

// Overlay
.hero--with-content {
    &::after {
        content: "";
        @include absolute-cover;
        background: rgba($c-hero-overlay, $u-hero-overlay-opacity);
        visibility: visible;
    }
}

.hero__content {
    position: absolute;
    width: 100%;
    top: 50%;
    text-align: center;
    z-index: 2;
    color: $c-hero-title;
}

#{$md-transforms} {
    .hero__content {
        transform: translateY(-50%);
    }
}

.hero__title {
    @include hero-title;
    margin-bottom: 0;
}
.hero__intro {
    @include intro;
    // margin-top: $u-small-spacer;
}
.hero__cta {
    margin-top: $u-spacer;
}
