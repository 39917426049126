@mixin medium-title-non-responsive {
    @include title;
    @include rem($u-medium-title);
}

@mixin large-title {
    @include title;
    @include rem($u-large-title);
    line-height: 47px;

    @include breakpoint(tablet) {
        @include rem($u-large-title * .85);
        line-height: 36px;
    }
}

@mixin medium-title {
    @include title;
    @include rem($u-medium-title);

    @include breakpoint(tablet) {
        @include rem($u-medium-title * .9);
    }
}

@mixin intro {
    @include rem($u-intro);
    @include apply-map($f-intro);
    display: block;
    text-decoration: none;
    a {
        color: inherit;
        text-decoration: inherit;
    }

    @include breakpoint(mobile) {
        @include rem($u-intro * .875);
    }

}
