// @author Brendan Patterson <brendan@d3r.com>

$default-media-layout: "stack" !default;
$default-object-size: 18% !default;
$default-content-size: 82% !default;
$default-media-gutter: 50px !default;
$default-vertical-align: top !default;
$default-text-align: left !default;

@mixin media(
    $l-layout: $default-media-layout,
    $l-object-size: $default-object-size,
    $l-content-size: $default-content-size,
    $l-gutter-size: $default-media-gutter,
    $l-vertical-align: $default-vertical-align,
    $l-text-align: $default-text-align,

    $m-layout: $l-layout,
    $m-object-size: $l-object-size,
    $m-content-size: $l-content-size,
    $m-gutter-size: $l-gutter-size,
    $m-vertical-align: $l-vertical-align,
    $m-text-align: $l-text-align,

    $s-layout: $m-layout,
    $s-object-size: $m-object-size,
    $s-content-size: $m-content-size,
    $s-gutter-size: $m-gutter-size,
    $s-vertical-align: $m-vertical-align,
    $s-text-align: $m-text-align

    ){

    $media-layouts: (
        l: $l-layout,
        m: $m-layout,
        s: $s-layout
    );

    $media-object-size: (
        l: $l-object-size,
        m: $m-object-size,
        s: $s-object-size
    );

    $media-content-size: (
        l: $l-content-size,
        m: $m-content-size,
        s: $s-content-size
    );

    $media-gutter-size: (
        l: $l-gutter-size,
        m: $m-gutter-size,
        s: $s-gutter-size
    );

    $media-vertical-align: (
        l: $l-vertical-align,
        m: $m-vertical-align,
        s: $s-vertical-align
    );

    $media-text-align: (
        l: $l-text-align,
        m: $m-text-align,
        s: $s-text-align
    );

    direction: ltr;
    @include remove-white-space;

    @each $name, $breakpoint in $sizes {
        @include breakpoint($breakpoint) {

            // Object and content
            & > :first-child,
            & > :last-child {
                direction: ltr;
                display: block;
                width: 100%;
                @include flush-last-child;
                text-align: map-get($media-text-align, $name);
            }

            @if $ie8-support == true {
                /*
                ie8 doesn't support :last-child and will ignore the entire rule if its used, however it does support '+' so using this to target the same element - needs to be a stand alone rule though to avoid being ignored
                */
                & > :first-child,
                #ie8 & > :first-child + * {
                direction: ltr;
                display: block;
                width: 100%;
                @include flush-last-child;
                text-align: map-get($media-text-align, $name);
                }
            }

            // Object
            & > :first-child {
            }

            // Content
            & > :last-child {
                padding: 0;
                padding-top: map-get($media-gutter-size, $name);
            }

            @if $ie8-support == true {
                #ie8 & > :first-child + * {
                    padding: 0;
                    padding-top: map-get($media-gutter-size, $name);
                }
            }

            // Flag
            @if map-get($media-layouts, $name) == flag {

                // Object and content
                & > :first-child,
                & > :last-child {
                    display: inline-block;
                    // Only applicable for flag layout
                    vertical-align: map-get($media-vertical-align, $name);
                }

                @if $ie8-support == true {
                    & > :first-child,
                    #ie8 & > :first-child + * {
                        display: inline-block;
                        vertical-align: map-get($media-vertical-align, $name);
                    }
                }

                // Obejct
                & > :first-child {
                    width: map-get($media-object-size, $name);
                }

                // Content
                & > :last-child {
                    width: map-get($media-content-size, $name);
                    padding: 0;
                    padding-left: map-get($media-gutter-size, $name);
                }

                & > :only-child {
                    width: 100%;
                    padding: 0;
                }

                @if $ie8-support == true {
                    #ie8 & > :first-child + * {
                        width: map-get($media-content-size, $name);
                        padding: 0;
                        padding-left: map-get($media-gutter-size, $name);
                    }
                }

                // Reverse order
                &--reverse {
                    direction: rtl;

                    // Change 'Content' padding from left to right
                    & > :last-child {
                        padding: 0;
                        padding-right: map-get($media-gutter-size, $name);
                    }

                    & > :only-child {
                        padding: 0;
                    }

                    @if $ie8-support == true {
                        #ie8 & > :first-child + * {
                            padding: 0;
                            padding-right: map-get($media-gutter-size, $name);
                        }
                    }

                }

            }

            // Debug
            @if $debug == true {

                // Debug if more than 2 children
                & > :nth-child(3) {
                    display: block;
                    &::before {
                        display: block;
                        padding: 10px;
                        border: 2px solid $c-negative;
                        content: "Warning: Exceeded maximum of 2 children per media object - See media samples page";
                        font-size: 20px;
                        font-weight: bold;
                        color: $c-negative;
                    }
                }

                // Debug if id's or classes are used on children
                & > :first-child,
                & > :last-child {
                    &[class],
                    &[id] {
                        &::before {
                            display: block;
                            padding: 10px;
                            border: 2px solid $c-negative;
                            content: "Warning: Do not use classes or Id's on the children of a media object";
                            font-size: 20px;
                            font-weight: bold;
                            color: $c-negative;
                        }
                    }
                }

            }
        }
    }
}
