.header-menu {
    @include hideScrollBar;

    min-height: 100vh;
    height: 100%;
    background-color: var(--background);
    color: var(--foreground);
    min-width: to-rem(430px);
    max-width: to-rem(430px);
    transition: transform 500ms cubic-bezier(.91,.18,.51,.98);
    transform: translateX(-100%);
    will-change: transform;
    position: fixed;
    overflow: scroll;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 65;
    pointer-events: none;
    padding-bottom: to-rem(80px);

    @include breakpoint(phablet) {
        width: 100%;
        max-width: 100%;
    }

    .body--debug:not([data-header-position="sticky"]) & {
        top: 22px;
    }

    .body--admin:not([data-header-position="sticky"]) & {
        top: 44px;
    }

    .body--debug.body--admin:not([data-header-position="sticky"]) & {
        top: 64px;
    }

    &[data-scroll-lock-scrollable] {
        overflow-y: auto;
    }
}

.header-menu--active {
    transform: translateX(0);
    pointer-events: initial;
}

    .header-menu__toggle {
        @include font(16px, 20px);

        letter-spacing: 2px;
        color: inherit;
        border-color: inherit;
        position: absolute;
        top: to-rem(25px);
        left: to-rem($site-gutter);
        padding: 0;
        border: none;
        height: 45px;

        @include breakpoint(phablet) {
            height: auto;
            top: to-rem(34px);
            left: to-rem($site-gutter--mobile);
        }

        &:hover {
            color: var(--foreground);
            background-color: transparent;
        }
    }

    .header-menu__nav {
        padding: to-rem(90px) to-rem(75px);

        @include breakpoint(phablet) {
            padding: to-rem(80px) to-rem($site-gutter--mobile) to-rem(60px) to-rem(60px);
        }

        .button.button {
            color: var(--foreground);
            border-color: var(--foreground);

            &:hover {
                color: var(--background);
                background-color: var(--foreground);
            }
        }
    }

        .header-menu__nav-list {
            margin-bottom: to-rem(20px);
        }

            .header-menu__list-item {
                position: relative;

                @include breakpoint(phablet) {
                    margin: 1px 0;
                }
            }

            .header-menu__list-item--has-children {
                display: grid;
                grid-template-columns: min-content;
                grid-template-rows: max-content 0fr;
                transition: grid-template-rows 0.2s ease-in-out;
            }

            .header-menu__list-item--active {
                grid-template-rows: max-content 1fr;
            }

                .header-menu__list-link {
                    @include apply-map($f-title);
                    @include font(22px, 42px);

                    text-transform: uppercase;
                    grid-row: 1 / span 1;
                    white-space: nowrap;
                }

                .header-menu__list-toggle {
                    display: grid;
                    place-items: center;
                    grid-row: 1 / span 1;
                    border: none;
                    padding: 0;
                    width: 40px;
                    color: currentColor;

                    &:hover {
                        svg {
                            fill: var(--foreground);
                        }
                    }

                    svg {
                        fill: currentColor;
                        transition: transform 150ms ease;
                        width: 20px;

                        .header-menu__list-item--active & {
                            transform: rotate(180deg);
                        }
                    }

                    &:hover {
                        background-color: transparent;
                    }
                }

                .header-menu__sub-list {
                    overflow: hidden;
                    margin-left: to-rem(15px);
                }

                    .header-menu__sub-item {
                        @include apply-map($f-primary);
                        @include font(12px, 30px);

                        line-height: 1.5;
                        padding: 0.5em 0;

                        letter-spacing: 1.5px;
                        text-transform: uppercase;

                        &:last-child {
                            margin-bottom: to-rem(15px);
                        }
                    }
