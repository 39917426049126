
/* start buttons */
button,
.button {
    @include apply-map($f-button);
    @include rem($f-button-size);

    outline: none;
    cursor: pointer;
    text-decoration: none !important;
    background: transparent;
    overflow: visible;

    margin: 0;
    color: $c-button;
    background: $c-button-bg;
    -webkit-font-smoothing: antialiased; /*make it look nice in webkit*/
    text-align: center;
    vertical-align: middle;

    border-style: solid;
    border-width: $u-button-border;
    border-color: $c-button-border;
    padding: $u-button-padding;
    border-radius: $u-button-radius;
    transition: $c-button-transition;

    a:hover &,
    a:active &,
    &:hover,
    &:active {
        background-color: $c-button-bg-hover;
        color: $c-button-hover;
    }

    span {
        padding: 0 !important;
        display: inline;
    }
}
button {
    width: auto;
    white-space: nowrap;
    vertical-align: top;
}

.button--alt {
    background: $c-button-alt-bg;
    color: $c-button-alt;
    border-color: $c-button-alt-border;
    a:hover &,
    a:active &,
    &:hover,
    &:active {
        background-color: $c-button-alt-bg-hover;
        color: $c-button-alt-hover;
    }
}

#ie6 button,
#ie7 button {
    margin: -2px 0;
}

.button {
    display: -moz-inline-box; /*Firefox2*/
    display: inline-block;
}

button::-moz-focus-inner{padding:0;border:none;} /* FF Fix */

.button--full {
    width: 100%;
    box-sizing: border-box;

    span {
        padding: 10px 5px;
        text-align: center;
    }
}
