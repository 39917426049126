.spa-package {}

    .spa-package__description {
        margin-bottom: $u-med-spacer;
    }

    .spa-package__title {
        @include small-title;
        margin-bottom: $u-med-spacer;
    }

    .spa-package__list {
        margin-bottom: $u-med-spacer;
    }

    .spa-package__list-item {
        @include list-item(nowrap);
    }

    .spa-package__link {
        text-decoration: underline;
    }

    .spa-package__terms-link {
        margin-bottom: 15px;
        display: inline-block;
    }

    .spa-package__book-button {
        margin-bottom: 20px;
    }

    .spa-package__dates {
        max-width: 400px;
        text-align: left;
        margin: 20px auto;
    }

    .spa-package__date {
        input {
            margin-right: 10px;
        }
    }

    .spa-package__call {
        margin-top: $u-med-spacer;
        font-weight: bold;
    }
