.enquiry-details__button {
    margin-top: 20px;
}

    .enquiry-details__download a {
        margin: 5px 0;
        display: block;
        &:before {
            content: '';
            margin-right: 10px;
            display: inline-block;
            vertical-align: middle;
            @extend .icon--download;
        }
    }
