// $pswp__show-hide-transition-duration: 333ms;
// $pswp__controls-transition-duration: 333ms;
// $pswp__background-color: #000;
// $pswp__placeholder-color: #222;
// $pswp__box-sizing-border-box: true; // disable .pswp * { box-sizing:border-box } (in case you already have it in your site css)
// $pswp__root-z-index: 1500;
$pswp__assets-path: '/images/'; // path to skin assets folder (preloader, PNG and SVG sprite)
// $pswp__error-text-color: #CCC; // "Image not loaded" text color
// $pswp__include-minimal-style: true;

@import "../generic/photoswipe/main.scss";
@import "../generic/photoswipe/default-skin.scss";

.pswp__button, .pswp__button:hover {
    background-color: transparent;
}
.pswp__caption__center {
    text-align: center;
}
