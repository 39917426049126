.band {
    border-bottom: none;
    margin-top: $u-band-padding-top;
    margin-bottom: $u-band-padding-bottom;
    padding-top: 0;
    padding-bottom: 0;

    @include breakpoint(tablet) {
        margin-top: calc($u-band-padding-top / 2);
        margin-bottom: calc($u-band-padding-bottom / 2);
    }

    ~ .band--compact {
        margin-top: to-rem(-20px);
    }
}

.band--compact {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: calc($u-band-padding-top / 2);
    margin-bottom: calc($u-band-padding-bottom / 2);

    ~ .footer {
        margin-top: 0;
    }
}

.band--med {
    margin-top: $u-large-spacer;
    margin-bottom: $u-large-spacer;

    @include breakpoint(tablet) {
        margin-top: calc($u-band-padding-top / 2);
        margin-bottom: calc($u-band-padding-bottom / 2);
    }
}

.band--med-bottom {
    margin-bottom: $u-large-spacer;

    @include breakpoint(tablet) {
        margin-bottom: calc($u-band-padding-bottom / 2);
    }
}

.band--large-bottom-mobile {
    @include breakpoint(tablet) {
        margin-bottom: $u-band-padding-bottom;
    }
}

.band--small-bottom-mobile {
    @include breakpoint(tablet) {
        margin-bottom: calc($u-band-padding-bottom / 4);
    }
}

.band--flush {
    margin-top: 0;
    margin-bottom: 0;
}

.band--flush-top {
    margin-top: 0;
}

.band--flush-bottom {
    margin-bottom: 0;
}

.band--tab-space {
    margin-bottom: to-rem(40px);
}
