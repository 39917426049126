.grid {
    counter-reset: pins;
}
.listing {
    text-align: left;
}
.listing--neighbourhood {
    counter-increment: pins;

    .listing__details {
        padding-left: 50px;
        position: relative;

        &::before {
            transform: rotate(45deg);
            content: '';
            position: absolute;
            top: 5px;
            left: 5px;
            width: 22px;
            height: 22px;
            background-color: $c-brand;
        }

        &::after {
            content: counter(pins);
            @include apply-map($f-primary);
            @include rem(12px);
            color: $c-secondary;
            text-align: center;
            line-height: 31px;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 31px;
            height: 31px;
        }
    }

    .listing__title {
        @include apply-map($f-primary);
        @include rem(14px);

        text-transform: none;
    }

    .listing__action {
        display: flex;
        gap: to-rem(10px);
        justify-content: center;
    }
}

.map_infowindow__details {
    text-align: center;

    .listing__cta {
        color: $c-secondary;

        &::after {
            background-color: $c-secondary;
        }

        &:hover {
            background-color: transparent;
            color: $c-secondary;
            text-decoration: none;
        }
    }

    .listing__title {
        @include font(12px, 16px);

        margin-bottom: to-rem(3px)
    }

    .listing__meta {
        margin: to-rem(5px) 0;

        p {
            @include font(10px, 16px);

            letter-spacing: 1.5px;
        }
    }

        .listing__meta-category {
            @include apply-map($f-title);
            @include tiny-title;

            text-transform: uppercase;
        }

    .listing__action {
        margin-top: to-rem(5px);
    }
}

.listing__cta {
    position: relative;
    border: 0;
    padding: 0;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        background-color: $c-brand;
        width: 100%;
    }

    &:hover {
        background-color: transparent;
        color: $c-brand;
        text-decoration: none;
    }

    &:hover::after {
        animation: growBorder 400ms ease forwards;
    }
}


.listing--room {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: flex-start;

    .image-overlay {
        flex: 0 0 100%;
        margin-bottom: 20px;
    }
        .listing__image {
            margin: 0;
        }

    .listing__meta {
        &:first-child {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: -15px;
                left: 50%;
                transform: translateX(-50%) rotate(45deg);
                width: 7px;
                height: 7px;
                background-color: $c-secondary
            }
        }
    }

    .listing__title {
        margin: 0;
        max-width: 100%;
        line-height: 28px;

        @include breakpoint(tablet) {
            margin-bottom: 8px;
        }
    }

    .listing__action {
        min-width: 100px;
        text-align: right;
        text-wrap: nowrap;
        margin-top: 0;

        @include breakpoint(tablet) {
            display: flex;
            gap: to-rem(20px);
        }
    }

    .listing__details {
        height: 100%;
        padding-right: to-rem(20px);
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: to-rem(20px);

        @include breakpoint(tablet) {
            height: auto;
            flex-direction: column;
            align-items: flex-start;
            gap: 0;
        }
    }

    .listing__description {
        @include font(12px, 28px);

        letter-spacing: 1px;
        color: $c-secondary;
        text-transform: uppercase;
        position: relative;

        @include breakpoint(tablet) {
            color: $c-brand;
            margin-bottom: to-rem(10px);
        }

        &::before {
            content: '';
            position: absolute;
            top: -15px;
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
            background-color: $c-secondary;
            height: 7px;
            width: 7px;

            @include breakpoint(tablet) {
                display: none;
            }
        }
    }

    .listing__cta {
        margin-top: to-rem(15px);

        &::after {
            background-color: $c-secondary;
        }

        &:hover {
            background-color: transparent;
            color: $c-secondary;
        }
    }

    .listing__meta {
        @include apply-map($f-primary);
        @include font(12px, 28px);

        letter-spacing: 1px;
        font-weight: 500;
        text-transform: uppercase;
        margin: 0;
    }

    .listing__mobile {
        display: none;

        @include breakpoint(tablet) {
            margin-bottom: to-rem(16px);
            display: flex;
            flex-wrap: wrap;

            p {
                @include apply-map($f-primary);
                @include font(10px, 18px);

                position: relative;
                text-transform: uppercase;
                letter-spacing: 0.5px;

                &:not(:last-child) {
                    margin-right: to-rem(25px);

                    &::before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        right: to-rem(-15px);
                        left: initial;
                        transform: translateY(-50%) rotate(45deg);
                        background-color: $c-brand;
                        height: 6px;
                        width: 6px;
                    }
                }
            }
        }
    }
}
