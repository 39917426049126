.pagination {
    margin-top: 40px;
    @include clearfix;
}

    .pagination__prev {
        float: left;
    }

    .pagination__next {
        float: right;
    }
    .pagination__count {
        float: right;
        margin: 0 20px;
        padding: $u-button-padding;
        padding-left: 5px;
        padding-right: 5px;
    }
