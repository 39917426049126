.section-intro {
    @include intro;
    @include max-width-content;
    text-align: center;
    margin-bottom: $u-spacer;
}
    .section-intro--flush-bottom {
        margin-bottom: 0;
    }

.section-intro__category {
    @include category;
    margin-bottom: 10px;
}

.section-intro__title--primary {
    @include page-title;
}

.section-intro__title {
    @include large-title;
}

.section-intro__jump-nav {

}

.section-intro__highlight {
    @include copy;
    font-weight: bold;
    margin-top: $u-small-spacer;
}

.section-intro__copy {
    @include copy;
    margin-top: $u-med-spacer;
}
