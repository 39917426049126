@use 'sass:math';

.block--banner {
    .block__image {
        @include aspect(math.div(7, 3));

        img {
            width: 100%;
            height: auto;
        }

        @include breakpoint(phablet) {
            @include aspect(math.div(7, 5));
        }
    }
}
