// breadcrumb

.breadcrumb {
    @include remove-white-space;
    @include breakpoint(mobile) {
        display: none;
    }

}

.band--breadcrumb .justify {
    @include breakpoint(mobile) {
        text-align: center;
        display: block;
    }
}

.breadcrumb__item {
    margin-right: 0.5em;
    vertical-align: middle;

}

.breadcrumb__item--current {
    pointer-events: none;
    .breadcrumb__link {
        color: $c-breadcrumb-active;
    }
}

.breadcrumb__icon {
    vertical-align: middle;
    display: inline-block;
    margin-left: 0.5em;
}

.breadcrumb__link {
    @include apply-map($f-breadcrumb);
    @include rem($f-breadcrumb-size);
    vertical-align: middle;
    color: $c-breadcrumb;
    &:hover {
        text-decoration: underline;
    }
}

.band--breadcrumb {
    padding: $u-breadcrumb-band-padding;
}
