
// h1, h2, h3, h4, h5, h6, p {
//     margin-bottom: 0.5em;
//     &:empty {
//         margin-bottom: 0;
//     }
// }

h1, h2, h3, h4, h5, h6 {
    // font-size here
}

a {
    color: inherit;
    text-decoration: none;
    transition: color 250ms ease-in-out;
}


/* --------- Master typographic styles --------- */

// @author Brendan Patterson <brendan@d3r.com>

/*
- Extend to base typographical styles - inline with itcss structure
- Only ever extend to an %ex- to avoid unintentional extensions
- Margins & alignment excluded from base styles
*/

// Body copy
@mixin copy {
    line-height: $f-copy-lineheight;
    @include apply-map($f-primary);
    @include rem($u-copy);
    color: $c-copy;
}

@mixin title {
    // display: block;
    @include apply-map($f-title);
    margin-bottom: $u-title-margin-bottom;
    line-height: $f-title-lineheight;
}

@mixin hero-title {
    @include title;
    @include rem($u-hero-title);

    @include breakpoint(tablet) {
        @include rem($u-hero-title * .8);
    }

    @include breakpoint(mobile) {
        @include rem($u-hero-title * .6);
    }
}

@mixin page-title {
    @include title;
    @include rem($u-page-title);

    @include breakpoint(tablet) {
        @include rem($u-page-title * .8);
    }

    @include breakpoint(mobile) {
        @include rem($u-page-title * .7);
    }
}

@mixin large-title {
    @include title;
    @include rem($u-large-title);

    @include breakpoint(tablet) {
        @include rem($u-large-title * .8);
    }

    @include breakpoint(mobile) {
        @include rem($u-large-title * .7);
    }
}

@mixin medium-title {
    @include title;
    @include rem($u-medium-title);

    @include breakpoint(tablet) {
        @include rem($u-medium-title * .8);
    }

    @include breakpoint(mobile) {
        @include rem($u-medium-title * .7);
    }
}

@mixin small-title {
    @include title;
    @include rem($u-small-title);
}

@mixin tiny-title {
    @include title;
    @include rem($u-tiny-title);
}

@mixin intro {
    @include rem($u-intro);
    @include apply-map($f-intro);
    display: block;
    text-decoration: none;
    a {
        color: inherit;
        text-decoration: inherit;
    }

    @include breakpoint(mobile) {
        @include rem($u-intro * .8);
    }

}

// Smallprint
@mixin smallprint {
    @include apply-map($f-smallprint);
    @include rem($u-smallprint);
}


// Quicklink
@mixin smallcaps {
    @include apply-map($f-smallcaps);
    @include rem($f-link-size);
}

// Quicklink
@mixin link {
    @include apply-map($f-link);
    @include rem($f-link-size);
}

// Category (might have to be split into the various module cats specifically?)
@mixin category {
    @include smallcaps;
    @include rem($u-category);
    color: $c-category;
}
