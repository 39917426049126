.link-list-bar {
    border-bottom: 1px solid $c-border;
    margin-bottom: to-rem(30px);
    padding-bottom: 0;

    ul {
        display: flex;
        padding-bottom: to-rem(20px);
        justify-content: center;
        
        @include breakpoint(laptop) {
            @include hideScrollBar;

            justify-content: flex-start;
            overflow: scroll;
        }
    }
}

.link-list-bar__item {
    margin: 0 15px;
    text-align: center;

    &::before {
        display: none;
    }
}

.link-list-bar__link {
    @include apply-map($f-primary);

    white-space: nowrap;
}

.link-list-bar__link--active {
    font-weight: 700;
}
