@mixin toolbars($property) {
    .body--debug & {
        #{$property}-top: 20px;
    }

    .body--admin & {
        #{$property}-top: 44px;
    }

    .body--debug.body--admin & {
        #{$property}-top: 64px;
    }
}
