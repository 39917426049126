@use 'sass:math';

.block--image-text {
    display: grid;
    grid: auto / 3fr 2fr;
    gap: to-rem($grid-gutter-h);

    @include breakpoint(tablet) {
        grid-template-columns: 1fr;
    }

    &.block--align-left {
        grid-template-columns: 2fr 3fr;

        @include breakpoint(tablet) {
            grid-template-columns: 1fr;
        }

        .block__figure {
            grid-column: 2 / span 1;

            @include breakpoint(tablet) {
                grid-column: 1 / span 1;
            }
        }

        .block__text {
            grid-row: 1 / span 1;

            @include breakpoint(tablet) {
                grid-column: 1 / span 1;
                grid-row: 2 / span 1;
            }
        }
    }

    .block__figure {
        @include aspect(math.div(4, 3));
    }

    .block__button {
        margin-top: to-rem(35px);
    }

    .block__title--med {
        max-width: 375px;
    }

    .block__body {
        strong {
            display: block;
            margin-bottom: -15px;
        }
    }
}
